'use strict';

$(document).ready(function () {
  setClass('maintenance-container', 'maintenance-page');
  setClass('error-page-404', 'error-404');
});

/**
 * Function used to set layout page class in order to distinguish where we are
 * @param classToCheck is the class to search inside layout page
 * @param classToSet is the class to apply to layout page
 */
const setClass = (classToCheck, classToSet) => {
  document.querySelector('.main--layout-page >.' + classToCheck) ? document.querySelector('.main--layout-page').classList.add(classToSet) : null;
};
