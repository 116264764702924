'use strict';

const addToCartValidation = require('../product/addToCartValidation');
const base = require('base/product/base');
const isMargiela = document.querySelector('body').classList.contains('Margiela');
const isJilSander = document.body.dataset.sitebrand === "JilSander";
const isMarni = document.body.dataset.sitebrand === "Marni";
const processInclude = require('core/util');
let customSelect;



$(document).ready(function () {

  if(isMargiela) {
    processInclude(require('core/product/fitguide'));
  }

  if(isMarni) {
    customSelect = require('marni/components/customSelect').updateDisabledOptions();
  }

  if($('.product-notify .modal-content').length > 0) {
    const notify = processInclude(require('core/product/notifyWhenAvailable'));

    $('.notify-me-modal-button').on('click', function(e) {
  
    if(!isMargiela && ($(e.target).closest('form').find('.single-val-size.selected').length > 0 || $(e.target).closest('form').find('.notify-me-content-url').length > 0)) {
      let url = $(e.target).closest('form').find('.single-val-size.selected').data('notifyme-url');
      $(e.target).closest('form').find('.notify-me-content-url').length > 0 ? url = $(e.target).closest('form').find('.notify-me-content-url').data('notifyme-url') : '';
      $.ajax({
        url: url,
        method: 'GET',
        success: function (responseData) {
          const $container =  $('.product-notify .modal-content');
          if ($container.length > 0) {
            $container.empty().append(responseData.notifyWhenAvailableHtml);
          }
        },
        error: function () {
          console.log('Error while getting notify content');
        },
      });
    }
  });

  }

  

  const userCustomerGroupsUrl = $('.private-sales').data('userCustomerGroupsUrl');
  if(userCustomerGroupsUrl) {
  $.ajax({
    url: userCustomerGroupsUrl,
    method: 'GET',
    success: function (responseData) {
      if (!responseData.error) {
        $('.private-sales').each(function() {
          checkPrivateSales($(this), responseData.isUserAuthenticated, responseData.customerGroups);
        });
      }
    },
    error: function () {
      console.log('Error while getting customer groups');
    },
  });
  }
 
});

/**
 * appends params to a url
 * @param {string} data - data returned from the server's ajax call
 * @param {Object} button - button that was clicked to add a product to the wishlist
 * @param {string} action - button that was clicked to add a product to the wishlist
 */
function handleResponse(data, button, action = 'add') {
  $.spinner().stop();

  if (data.success) {
    showAriaHint(button,data.msg,action);

    const $quantity = $('.wishlist-quantity');
    (isMargiela ? $quantity : $('header .wishlist-quantity'))[data.listIsEmpty ? 'addClass' : 'removeClass']('d-none');

    if (action === 'remove') {
      button.removeClass('active');

      for (let i = 0; i < $quantity.length; i++) {
        $quantity[i].innerHTML = data.listIsEmpty ? 0 : parseInt($quantity[i].innerHTML, 10) - 1;
      }
    } else {
      button.addClass('active');

      for (let i = 0; i < $quantity.length; i++) {
        $quantity[i].innerHTML = parseInt($quantity[i].innerHTML, 10) + 1;
      }

      const modalpid = data.pid;
      $('.js_add-to-wish-list-cart[data-pid="' + modalpid + '"]').addClass('active');
      $('body').trigger('product:afterAddToWishlist', this);
    }

    if (isMargiela && action !== 'remove') {
      const event = new CustomEvent('notification:show', {
        detail: {
          message: data.msg,
          icon: true,
          linkCart: false,
          linkWishlist: true
        }
      });
      document.body.dispatchEvent(event);
    }

    isMargiela && $('.js-button-wishlist')[data.listIsEmpty ? 'removeClass' : 'addClass']('-dot-info');
  }

  setTimeout(function () {
    button.removeClass('disabled-click');
    button.attr('tabindex' , 0);
  }, 5000);
}

function showAriaHint(button,message,action) {
  let nonvisualHint = button.next('.visually-hidden');
  $(nonvisualHint).text(message);
  if (action === 'add') {
    button.attr('aria-pressed', 'true');
  } else {
    button.attr('aria-pressed', 'false');
  }
}

function showErrorMessage(form, message) {
  const errorContainer = $(form).closest('.product-tile--wishlist').find('.invalid-feedback');
  $(errorContainer).html(message);
  const button = $(form).find('.add-to-cart--wishlist');
  $(button).addClass('disabled');
  $(button).prop('disabled', true);
}

function hideErrorMessage(form) {
  const errorContainer = $(form).closest('.product-tile--wishlist').find('.invalid-feedback');
  $(errorContainer).html('');
  const button = $(form).find('.add-to-cart--wishlist');
  $(button).removeClass('disabled');
  $(button).prop('disabled', false);
}

function addToCartFromWishlist(form, e) {
  const globalAddToCartURL = document.querySelector('input[name="global-add-to-cart-url"]');
  const pidInput = form.getAttribute('data-pid');

  hideErrorMessage(form);
  if (globalAddToCartURL && pidInput) {
    $.ajax({
      url: globalAddToCartURL.value,
      method: 'POST',
      data: {
        quantity: 1,
        pid: pidInput,
      },
      success: function (responseData) {
        if (!responseData.error) {
          $('body').trigger('product:afterAddToCart', responseData); // eslint-disable-line no-undef
          $('.minicart-action').trigger('click');
          $('.js-quantity.quantity.counter').text(responseData.quantityTotal);
          
          if (isMargiela) {
            $('.js-button-minicart').addClass('-dot-info');
            $('.js-cart-header-numitems').text(responseData.quantityTotal).removeClass('d-none');
            document.body.dispatchEvent(new CustomEvent('notification:show', {
              detail: {
                message: responseData.message,
                icon: true,
                linkCart: true,
                linkWishlist: false
              }
            }));
            if ($('.cart-page').length > 0) {
              location.reload();
            }
          }
          if(isJilSander) {
            $('.js-cart-header-numitems').text(responseData.quantityTotal).removeClass('d-none');
            const removeItem = e.target && e.target.querySelector('.remove-from-wishlist');
            removeItem && removeItem.click();
            $(".minicart").trigger("count:update", responseData);
            if(window.isMobile()) {
              document.body.dispatchEvent(new CustomEvent('notification:show', {
                detail: {
                  message: responseData.message,
                  icon: true,
                  linkCart: true,
                  linkWishlist: false
                }
              }));
            } else {
              $('.mini-cart-content, .minicart-backdrop').addClass('show');
              $('body').trigger('minicart:load');
            }
            $('.minicart-backdrop, .continue-shopping-btn').on('click', () => {
              $('.mini-cart-content, .minicart-backdrop').removeClass('show').hide();
            });
          }
        } else {
          if (responseData.privateSalesError) {
            const errorContainer = $(form).closest('.product-tile--wishlist').find('.invalid-feedback');
            $(errorContainer).html(responseData.message);
            if (window.isMobile()) {
              window.location.href = $('.private-sales').data('loginRedirectUrl');
            } else {
              if ($('#login-dropdown[data-remote-content]').data('remoteContent').indexOf('rurl') < 0) {
                $('#login-dropdown[data-remote-content]').attr('data-remote-content', $('#login-dropdown[data-remote-content]').data('remoteContent') + '?rurl=7');
              }
              $('.login-dropdown .js-dropdown-trigger').trigger('click');
              $('body').one('user:loggedIn', function(e) {
                e.preventDefault();
                $('form.private-sales-product button.add-to-cart').trigger('click');
              });
              $('html, body').animate({ scrollTop: 0, }, 500 );
            }
          } else {
            showErrorMessage(form, responseData.message);
          }
        }

        $.spinner().stop();
      },
      error: function () {
        $.spinner().stop();
      },
    });
  }
}

function populateTile(product, masterID, targetForm) {
 let productTile;
 if(!isMargiela && !isMarni){
   productTile = targetForm.closest(`.product[data-pid="${masterID}"]`);
 } else {
   productTile = targetForm.closest(`.product-tile--wishlist[data-master-id="${masterID}"]`).closest(`.product`);
 }
  const tileImage = productTile.querySelector('.tile-image');

  // MARG-661
  isMargiela && tileImage && tileImage.removeAttribute('srcset');

  tileImage && isMarni ? tileImage.setAttribute('src', product.swipeableImages.large[0].url) : tileImage.setAttribute('src', product.images.large[0].urlLarge);
  isMarni ? productTile.querySelector('.single-element-content-image').href = product.modelFabricColorProductURl : '';
  isMarni ? productTile.querySelector('.single-element-content-detail-description .product-link').href = product.modelFabricColorProductURl : '';
  isMarni && product.wishlistProductNameForTile ? productTile.querySelector('.single-element-content-detail-description .product-link').innerHTML = product.wishlistProductNameForTile : '';
  const tileBody = productTile.querySelector('.tile-body');
  const price = productTile.querySelector('.price');
  price && (isMargiela ? price.parentNode : price).remove();

  if (isMargiela) {
    const info = tileBody.querySelector('.mm-product-card__info__default');
    info.insertAdjacentHTML('beforeend', product.price.html);
    const price2 = info.querySelector('.price');
    price && price2 && price.querySelectorAll(':scope > :not(.default-price-container)').forEach(e => price2.append(e));
  } else if(isMarni) {
    product.variationGroupPrice &&  product.variationGroupPrice.html ? tileBody.insertAdjacentHTML('beforeend', product.variationGroupPrice.html) : tileBody.insertAdjacentHTML('beforeend', product.price.html);
  } else {
    tileBody.insertAdjacentHTML('beforeend', product.price.html);
  }

  [...productTile.querySelectorAll('[data-pid]')].forEach((item) => {
    item.setAttribute('data-pid', product.id);
  });
  product.variationAttributes.forEach((attribute) => {
    if (isMargiela && attribute.attributeId === 'color') {
      const colorsBox = productTile.querySelector('.colors-box');
      colorsBox.innerHTML = attribute.values.map(color => {
        // app_project_margiela/cartridge/templates/default/product/components/productTileColors.isml
        const code = color.HEXColor ? color.HEXColor.split('|') : [];
        const backgroudColor = code.length == 0 ? '' : code.length > 1 ? `background: linear-gradient(90deg, ${code[0]} 50%, ${code[1]} 50%)` : `background-color: ${code[0]}`;
        return `<li class="mm-color-swatch ${code.length > 1 ? 'doubled-color' : ''}">
  <input
    type="radio"
    name="dwvar_${masterID}_color"
    data-name="variant_${color.displayValue}"
    value="${color.value}"
    data-display-value="${color.displayValue}"
    ${color.selected ? 'checked' : ''}
    class="value-${color.id} mm-color-swatch__inner ${color.selected ? 'selected-color' : ''} ${code.length > 1 ? 'doubled-color' : ''} wishlist__select"
    data-value-id="value-${color.id}"
    style='${backgroudColor}'
    form="${targetForm.id}" />
  <span class="visually-hidden">${color.displayValue}</span>
</li>`;
      }).join('');
      colorsBox.querySelectorAll('.wishlist__select').forEach(ws => ws.addEventListener('change', wishlistSelectChange));
      return;
    }
    const attributeSelect = productTile.querySelector(`[name="dwvar_${masterID}_${attribute.attributeId}"]`);
    if (!attributeSelect) {
      return;
    }
    attributeSelect.innerHTML = isMarni && attributeSelect.options[0].value === '' ? attributeSelect.options[0].outerHTML : '';
    attribute.values.forEach((value) => {
      const option = `<option class="${!value.selectable && isMarni ? 'disabled' : ''}" value="${value.value}" ${value.selected ? 'selected' : ''}
  ${'data-attr-value="' + value.value + '"'} ${!value.selectable && !isMargiela ? 'disabled' : ''}>
  ${value.displayValue}
  ${isMargiela && !value.selectable ? attributeSelect.dataset.notavailable : ''}
</option>`;
      attributeSelect.insertAdjacentHTML('beforeend', option);
    });
  });


  if(isMarni) {
    customSelect = require('marni/components/customSelect').updateDisabledOptions();
    var privateSalesSpan = productTile.querySelector('span.private-sales');
    product.isPrivateSalesProduct ? $(privateSalesSpan).data('isPrivateSalesProduct', true) : $(privateSalesSpan).data('isPrivateSalesProduct', false);
  
    if(product.isPrivateSalesProduct) {
      const userCustomerGroupsUrl = $(privateSalesSpan).data('userCustomerGroupsUrl');
      $.ajax({
        url: userCustomerGroupsUrl,
        method: 'GET',
        success: function (responseData) {
          if (!responseData.error) {
            $('.private-sales').each(function() {
              checkPrivateSales($(this), responseData.isUserAuthenticated, responseData.customerGroups);
            });
          }
        },
        error: function () {
          console.log('Error while getting customer groups');
        },
      });
    } else {
      const addToCartText = $(privateSalesSpan).data('addToCartText');
      $(privateSalesSpan).parent().find('span.add-to-cart--wishlist-text').html(addToCartText);
    }
  }


  if (isMargiela) {
    const notifyAndAddToCart = productTile.querySelector('.add-to-cart-switch');
    productTile.querySelector('.notify-me-content-url') ? productTile.querySelector('.notify-me-content-url').remove() : '';
    if (product.available) {
      notifyAndAddToCart.classList.remove('show-notify-me');
    } else if (!product.available && product.showBackInStockNotification) {
      notifyAndAddToCart.classList.add('show-notify-me');
    }
    const $container =  $('.product-notify .modal-content');
    if ($container.length > 0) {
      $container.empty().append(product.notifyWhenAvailableHtml);
    }

    var isSelectedSize = productTile.querySelector('.select-size').value ? true : false;

    productTile.querySelector('.add-to-cart-switch').classList.toggle('-active', isSelectedSize);
    productTile.querySelector('.size-switch').classList.toggle('-active', !isSelectedSize);

  } else {
    if (product.isOrderable) {
      productTile.querySelector('.add-to-cart--wishlist').removeAttribute('disabled');
      productTile.querySelector('.add-to-cart--wishlist').classList.remove('inactive-btn');
    } else {
      productTile.querySelector('.add-to-cart--wishlist').setAttribute('disabled', 'disabled');
      productTile.querySelector('.add-to-cart--wishlist').classList.add('inactive-btn');
    }
  }

    $.spinner().stop();
}

function bindAddToCart() {
  const wishlistItemAttributeSelect = [...document.querySelectorAll('.wishlist__select')];
  wishlistItemAttributeSelect.forEach((select) => {
    select.addEventListener('change', wishlistSelectChange);
    const options = [...select.querySelectorAll('option')];
  });

  const addToCartForm = [...document.querySelectorAll('form[name="add-to-cart-from-wishlist"]')];
  addToCartForm.forEach((form) => {
    form.addEventListener('submit', (e) => {
      e.preventDefault();
      if (addToCartValidation($(form))) {
        addToCartFromWishlist(form, e);
      }
    });
  });
}

function wishlistSelectChange(e) {
  const target = e.currentTarget;
  const parentForm = target.form;
  $(e.target).parent().removeClass('invalid');
  hideErrorMessage(parentForm);
  
  if(isMarni) {
    $('select.select-color option').prop('disabled', false);
    if(!isMobile()) {
      const btnVal = $(e.target).parent().find('.fsb-select .fsb-button span').text();
      const ariaSelected = $(e.target).parent().find("span.fsb-list").find("[aria-selected='true']").attr('value');
      ariaSelected ? $(e.target).val(ariaSelected) : $(e.target).parent().find('.fsb-original-select option').filter(function() { return $.trim($(this).text()) == btnVal;}).prop('selected', true);
    }
  }

  if (parentForm.checkValidity()) {
    $.ajax({
      url: parentForm.getAttribute('action'),
      method: 'GET',
      data: $(parentForm).serialize(),
      beforeSend: () => {
        $.spinner().start();
      },
      success: function (responseData) {
        target.dispatchEvent(new Event('blur'));
        parentForm.setAttribute('data-pid', responseData.product.id);
        parentForm.querySelector('input[name="ean"]').value = responseData.product.id;
        populateTile(responseData.product, parentForm.querySelector('input[name="pid"]').value, parentForm);
      },
      error: function () {
        $.spinner().stop();
      },
    });
  }
}

/**
 * Decrease the number in the wishlist counter in header when an item is deleted,
 * or hide the wishlist icon if there are no more items after the deletion.
 */
function decreaseWishlistCounter() {
  const $quantity = $('body').find('.wishlist-quantity');
  if (!$quantity.length) return;

  let qty = parseInt($quantity[0].innerHTML, 10);

  qty = qty > 1 ? qty - 1 : 0;
  for (let i = 0; i < $quantity.length; i++) {
    $quantity[i].innerHTML = qty;
  }
          
  if (isMargiela) {
    $('.js-button-wishlist')[qty > 0 ? 'addClass' : 'removeClass']('-dot-info');
    $quantity[qty > 0 ? 'removeClass' : 'addClass']('d-none');
  }
}

/**
 * @param {Object} $elementAppendTo - The element to append error html to
 * @param {string} msg - The error message
 * display error message if remove item from wishlist failed
 */
function displayErrorMessage($elementAppendTo, msg) {
  if ($('.remove-from-wishlist-messages').length === 0) {
    $elementAppendTo.append('<div class="remove-from-wishlist-messages "></div>');
  }

  $('.remove-from-wishlist-messages').append(
    '<div class="remove-from-wishlist-alert text-center alert-danger">' + msg + '</div>'
  );

  setTimeout(function () {
    $('.remove-from-wishlist-messages').remove();
  }, 3000);
}

/**
 * renders the list up to a given page number
 * @param {number} pageNumber - current page number
 * @param {boolean} spinner - if the spinner has already started
 */
function renderNewPageOfItems(pageNumber, spinner) {
  const publicView = $('.wishlistItemCardsData').data('public-view');
  const listUUID = $('.wishlistItemCardsData').data('uuid');
  const url = $('.wishlistItemCardsData').data('href');
  const account = $('.wishlistItemCardsData').data('account');

  if (spinner) {
    $.spinner().start();
  }

  const scrollPosition = document.documentElement.scrollTop;
  let newPageNumber = pageNumber;
  $.ajax({
    url: url,
    method: 'get',
    data: {
      pageNumber: ++newPageNumber,
      publicView: publicView,
      id: listUUID,
      account: account,
    },
  })
    .done(function (data) {
      $('.wishlistItemCards').empty();
      $('body .wishlistItemCards').replaceWith(data);
      bindAddToCart();
      document.documentElement.scrollTop = scrollPosition;
      if(document.querySelector('.mm-video-card:not(.observed)')) {
        $('body').trigger('wishlist:update-plp-cards');
      }
    if(isMarni) {
      loadScript('https://cdn.jsdelivr.net/gh/mdbassit/FancySelect@latest/dist/fancyselect.min.js');
    }
      
    })
    .fail(function () {
      $('.more-wl-items').remove();
    });
  $.spinner().stop();
}

function loadScript(url) {
  document.querySelector('script[src$="'+ url +'"]')  ? document.querySelector('script[src$="'+ url +'"]').remove() : '' ;
  let script = document.createElement('script');
  script.src = url;
  document.body.appendChild(script);
}

function bindRemoveFromWishlist(){
  $('body').on('click', '.remove-from-wishlist', function (e) {
    e.preventDefault();
    const url = $(this).data('url');
    const elMyAccount = $('.account-wishlist-item').length;

    // If user is in my account page, call removeWishlistAccount() end point, re-render wishlist cards
    if (elMyAccount > 0) {
      $('.wishlist-account-card').spinner().start();
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'html',
        data: {},
        success: function (html) {
          $('.wishlist-account-card>.card').remove();
          $('.wishlist-account-card').append(html);
          decreaseWishlistCounter();
          bindAddToCart();
          $('.wishlist-account-card').spinner().stop();
        },
        error: function () {
          const $elToAppend = $('.wishlist-account-card');
          $elToAppend.spinner().stop();
          const msg = $elToAppend.data('error-msg');
          displayErrorMessage($elToAppend, msg);
        },
      });
      // else user is in wishlist landing page, call removeProduct() end point, then remove this card
    } else {
      $.spinner().start();
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: {},
        success: function () {
          decreaseWishlistCounter();
          const pageNumber = $('.wishlistItemCardsData').data('page-number') - 1;
          renderNewPageOfItems(pageNumber, false);
          const backToAccountLink = document.querySelector('.wishlist-page .back-to-account-link');
          const products = document.querySelectorAll('.wishlistItemCards ul li');
          if(isJilSander && backToAccountLink && (products && products.length === 1)) {
            backToAccountLink.classList.add('d-none');
          }
        },
        error: function () {
          $.spinner().stop();
          const $elToAppendWL = $('.wishlistItemCards');
          const msg = $elToAppendWL.data('error-msg');
          displayErrorMessage($elToAppendWL, msg);
        },
      });
    }
  });
}

function checkPrivateSales($privateSales, isUserAuthenticated, customerGroups) {
  const isPrivateSalesProduct = $privateSales.data('isPrivateSalesProduct');
  if (isPrivateSalesProduct) {
    const privateSalesCustomerGroups = $privateSales.data('privateSalesCustomerGroups');
    const privateSalesText = $privateSales.data('privateSalesText');
    const privateSalesTextLoggedIn = $privateSales.data('privateSalesTextLoggedIn');

    let isCustomerAllowed = false;
    for (const [productKey, productValue] of Object.entries(privateSalesCustomerGroups)) {
      for (const [customerKey, customerValue] of Object.entries(customerGroups)) {
        if (productValue === customerValue) {
          isCustomerAllowed = true;
          break;
        }
      }
      if (isCustomerAllowed) {
        break;
      }
    }

    if (!isCustomerAllowed) {
      if (!isUserAuthenticated) {
        $privateSales.closest('.form-add-to-cart-from-wishlist').addClass('private-sales-product');
        $privateSales.parent().find('span.add-to-cart--wishlist-text').html(privateSalesText);

       let loginRemoteContent = $('.login-dropdown [data-remote-content]');
        if (loginRemoteContent.data('remoteContent').indexOf('rurl') < 0) {
          loginRemoteContent.attr('data-remote-content', loginRemoteContent.data('remoteContent') + '?rurl=7');
        }
      } else {
        $privateSales.closest('.form-add-to-cart-from-wishlist').addClass('private-sales-product');
        $privateSales.parent().find('span.add-to-cart--wishlist-text').html(privateSalesTextLoggedIn);
      }
    }
  }
}

module.exports = {
  addToCartFromWishlist: () => {
      bindAddToCart();
  },
  bindCartRecommendations: function () {
    $('body').on('click', '.mm-button.cart-lineitem-recommendation', function(e) {
      var selector = '.' + e.currentTarget.dataset.actionPid;
      if ($(selector)[0].classList.contains('visually-hidden')) {
        $(selector)[0].classList.remove('visually-hidden');
      } else {
        $(selector)[0].classList.add('visually-hidden');
      }
      bindAddToCart();
    });
  },
  addToWishlist: function () {
    $(document).on('click', '.js_add-to-wish-list', function (e) {
      const isActiveWishList = e.currentTarget && e.currentTarget.classList.contains('active');
      if(!isJilSander || (isJilSander && !isActiveWishList)) {
        e.preventDefault();
      }
      const $button = $(this);
      const shouldValidate = $button.data('validateProduct');
      let url = $button.data('add');
      let action = 'add';

      if ($button.hasClass('active')) {
        url = $button.data('remove');
        action = 'remove';
      }

      let $productContainer = $button.closest('.set-item');

      if (!$productContainer.length) {
        $productContainer = $button.closest('.product-detail');
      }

      if ($(this).parent().hasClass('sticky-button') && shouldValidate && addToCartValidation($productContainer) === false) {
        $('html, body').animate(
          {
            scrollTop: $('#sticky-error-scroll-to').offset().top,
          },
          300
        );
      }

      if (shouldValidate && addToCartValidation($productContainer) === false) return;

      let pid = e.currentTarget.dataset.pid;

      // // Check if the product is a shop the look item
      // let isShopTheLookProduct = $button.closest('.product-detail').hasClass('stl-product-wrapper');

      // // Check if the product is a recommender item
      // let isShopTheLookRecommendation = $button.closest('.recommendations-tile').hasClass('stl-recommendation');

      // if (isShopTheLookRecommendation) {
      //   pid = $button.closest('.recommendations-tile').find('.product').data('pid');
      // } else if (isShopTheLookProduct) {
      //   pid = $button.data('pid');
      // } else if ($button.closest('#editWishlistProductModal').length > 0) {
      //   pid = base.getPidValue($(this));
      // } else if ($button.closest('#editProductModal').length > 0) {
      //   pid = this.dataset.pid;
      // } else if ($button.closest('.product-detail').find('.add-to-cart').length > 0 && $button.closest('.product-detail').find('.add-to-cart').attr('data-pid')) {
      //   pid = $button.closest('.product-detail').find('.add-to-cart').attr('data-pid');
      // } else {
      //   pid = $button.data('pid');
      // }

      let optionId = $button.closest('.product-detail').find('.product-option').attr('data-option-id');
      let optionVal = $button.closest('.product-detail').find('.options-select option:selected').attr('data-value-id');
      optionId = optionId || null;
      optionVal = optionVal || null;

      if (!url || !pid) {
        return;
      }

      $.spinner().start();
      const wishlistErrorMessage = document.querySelector('.wishlist-error-message');
      $button.addClass('disabled-click');
      $button.attr({'tabindex':-1, 'aria-pressed':true})

      const addToWishListError = $('.js-addtowishlist-error');
      const addToWishListErrorText = addToWishListError && addToWishListError.data('addtowishlist-alert');
      const addToWishListErrorMessage = $('.js-addtowishlist-error-message');
      
      if(isJilSander && addToWishListErrorText && addToWishListErrorMessage) {
        if ($('.selected-color').length>0 && $('.do_selectsize.selected').length>0) {
          addToWishListError.addClass('is-hidden');
        } else {
          addToWishListError.removeClass('is-hidden');
          addToWishListErrorMessage.empty().append(addToWishListErrorText);
          return;
        }
      }

      $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: {
          pid: pid,
          optionId: optionId,
          optionVal: optionVal,
        },
        success: function (data) {
          handleResponse(data, $button, action);
          if (isMarni) {
            if (data.wishlistCountOfItems == 'wishlist.count') {
              $('.action.wishlist-icon-selector')[0].classList.remove('icon--heart-new');
              $('.action.wishlist-icon-selector')[0].classList.add('icon--heart-new-red');
            } else {
              $('.action.wishlist-icon-selector')[0].classList.remove('icon--heart-new-red');
              $('.action.wishlist-icon-selector')[0].classList.add('icon--heart-new');
            }
          }
          if(isJilSander && wishlistErrorMessage) {
            if(data.error) {
              wishlistErrorMessage.innerText = data.msg;
              wishlistErrorMessage.classList.remove('d-none');
            } else {
              !wishlistErrorMessage.classList.contains('d-none') && wishlistErrorMessage.classList.remove('d-none');
            }
          }
          if (isMargiela & $('#edit-product-modal.is-open').length > 0) {
            $('#edit-product-modal.is-open .add-to-wish-list-icon')[0].innerHTML = data.label;
            $('#edit-product-modal.is-open .add-to-wish-list-icon')[1].innerHTML = data.label;
          }
        },
        error: function (err) {
          handleResponse(err, $button, action);
        },
      });
    });

    $(document).on('click', '.js_add-to-wish-list-cart', function (e) {
      e.preventDefault();
      const $button = $(this);

      if ($button.hasClass('active')) {
        return;
      }

      const url = $button.data('href');
      const pid = $button.data('pid');

      if (!url || !pid) {
        return;
      }
      
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: {
          pid: pid,
        },
        success: function (data) {
          $button.addClass('active');
          handleResponse(data, $button);
        },
        error: function (err) {
          handleResponse(err, $button);
        },
      });
    });
  },
  removeFromWishlist: function () {
    bindRemoveFromWishlist();
  },
  addAllToCart: function () {
    $('body').on('click', '.js_add-all-to-cart', function () {
      $('body').trigger('product:beforeAddToCart', this);
      const addToCartUrl = document.querySelector('input[name="global-add-to-cart-url"]')
        ? document.querySelector('input[name="global-add-to-cart-url"]').value
        : null;
      const pidsObj = [];
      [
        ...document.querySelectorAll(
          '.wishlistItemCards .product-grid-container .product-tile__actions form[name="add-to-cart-from-wishlist"]'
        ),
      ].forEach((item) => {
        if (addToCartValidation($(item))) {
          if (item.querySelector('input[name="ean"]') && !item.classList.contains('private-sales-product')) {
            const pid = item.querySelector('input[name="ean"]').value;
            pidsObj.push({
              pid: pid,
              qty: 1,
            });
          }
        }
      });

      if (addToCartUrl && pidsObj.length > 0) {
        const form = {
          pidsObj: JSON.stringify(pidsObj),
        };

        $.ajax({
          url: addToCartUrl,
          method: 'POST',
          data: form,
          beforeSend: () => {
            $.spinner().start();
          },
          success: function (data) {
            let currentQty = 0;
            $('.js-quantity.quantity.counter').each(function(i,e){
              currentQty = parseInt($(e).text());
            });
            if (data.quantityTotal > currentQty) {
              // Tealium event "Cart Add Full List"
              data.addAllProductsFromWishList = true;
              $('body').trigger('product:afterAddToCart', data);
              $('.minicart-action').trigger('click');
              $('.js-quantity.quantity.counter').text(data.quantityTotal);
            }

            $.spinner().stop();
          },
          complete: function () {
            $.spinner().stop();
          },
        });
      }
    });
  },
  updateSelectAttr : function () {
    $('body').on('click', '.wishlist-container_select button.fsb-button' ,function(){
      if(isMarni) {
        customSelect = require('marni/components/customSelect').updateDisabledOptions();
      }
    })
  },
};
