'use strict';

/**
 * Handle change of the href by using data-href for the product tiles anchors.
 * @returns {void}
 */
function handleTileLinks() {
  $('body').on('click', '.product-tile .tile-image-link, .product-tile .product-link', function (event) {
    const link = event.currentTarget;
    if (link.dataset.href) {
      const newHref = new URL(link.dataset.href, window.location.origin);
      if (window.location.hash) newHref.searchParams.set('currentPLP', window.location.hash);
      link.setAttribute('href', newHref.toString());
    }
  });
}

module.exports = {
  handleTileLinks: handleTileLinks,
};
