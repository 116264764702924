const showOrHide = (el, op) => {
  let scrollDistance = 0;
  const isMarni = document.body.dataset.sitebrand === 'Marni';
  if(op === 'show') {
    if (isMarni) {
      $('body')[0].style['overflow-y'] = 'hidden';
      $('body')[0].style['position'] = 'fixed';
      $('body')[0].style['width'] = '100%';
      // Get the scroll distance at the time the modal was opened
      scrollDistance = window.pageYOffset;
      $('body')[0].style['top'] = '-' + scrollDistance;
      const minicart = document.querySelector('.layer-minicart');
      minicart.dataset.lastoffset = scrollDistance;
    }
    document.querySelector(el).classList.add('is-open');
    setTimeout(() => {
      document.querySelector(el).classList.remove('animate-minicart-closure');
    }, 2000);
  } else {
  
    if (isMarni) {
        $('body')[0].style['overflow-y'] = 'visible';
        $('body')[0].style['position'] = 'unset';
        $('body')[0].style['top'] = '0';
        const minicart = document.querySelector('.layer-minicart').dataset.lastoffset;
    
        // // Set the window's scroll position back to what it was before the modal was opened
        window.scrollTo(0, minicart);
        // $(window).unbind('scroll');
      var height = -$('#minicart-layer-dropdown')[0].clientHeight - 200;
      // var padding = $('.minicart-modal .modal-footer')[0].clientHeight + 15;
      var root = document.documentElement;
      // root.style.setProperty('--slide-close', height + "px");
    }
    document.querySelector(el).classList.add('animate-minicart-closure');
    document.querySelector(el).classList.remove('is-open');
    setTimeout(() => {
      document.querySelector(el).classList.remove('animate-minicart-closure');
    }, 2000);
  }
}

$('.icon--bag-red').on('click', () => {
  const mainOverlay = document.getElementById('main-overlay');
  if(document.querySelector('body header.header')){
    document.querySelector('body header.header').classList.remove('z-index-0');
  }
  document.querySelector('.dropdown.layer-minicart.is-open') ? showOrHide('.layer-minicart #layer-minicart', 'hide') : showOrHide('.layer-minicart #layer-minicart', 'show');
  if(mainOverlay && window.innerWidth >= 992) {
    mainOverlay.style.display === '' || mainOverlay.style.display === 'none' ? mainOverlay.style.display = 'block' : mainOverlay.style.display === 'none';
  }
});


$(document).on('click', (e) => {
  if(e.target.classList.contains('minicart-close') || e.target.classList.contains('minicart-overlay') || e.target.classList.contains('minicart-notification-overlay')) {
    if (e.target.classList.contains('minicart-nofitication-close') || e.target.classList.contains('minicart-notification-overlay')) {
      showOrHide('.layer-notification-minicart', 'hide');
    } else {
      showOrHide('.layer-minicart', 'hide');
    }
  }

  if(e.target.classList.contains('filter-overlay') || e.target.classList.contains('layer-filter-close')) {
    showOrHide('.layer-filter', 'hide');

    setTimeout(() => {
      document.querySelector('body').classList.remove('no-zindex', 'layer-filter-open');
    }, 1000);
  }
});


$(document).on('click', (e) => {
  e.target.classList.contains('minicart-close') ? $('.icon--bag-red').trigger('click') : null;
  if(window.innerWidth >= 992 ) {
    const boipisItem = document.querySelector('.store-search-result .store-item');
    // update header z-index when user clicks on bopis trigger and close modal icon
    e.target.classList.contains('product-page') ? document.querySelector('body header.header').classList.add('z-index-0') : null;
    e.target.classList.contains('modal-close') ? document.querySelector('body header.header').classList.remove('z-index-0') : null;
    if(boipisItem && boipisItem.contains(e.target) && e.target.classList.contains('add-to-cart-actions')) {
      document.querySelector('.minicart-action').addEventListener('click', () => document.querySelector('body header.header').classList.remove('z-index-0'));
    }
  }
});

$(document).on('click', '.add-to-cart-recommendation', function () {
  $('body').trigger('product:beforeAddToCart', this);
  $.spinner().start();
  const addToCartUrl = $(this).attr('data-url');
  const productId = $(this).attr('data-pid');
  const updateMiniCartUrl = $(this).attr('data-minicart');

  if (addToCartUrl && productId) {
    const form = {
      pid: productId,
      quantity: 1,
    };
    $.ajax({
      url: addToCartUrl,
      method: 'POST',
      data: form,
      success: function (responseData) {
        if (!responseData.error) {
          $('body').trigger('product:afterAddToCart', responseData);
          updateMiniCart(updateMiniCartUrl);
          $('.js-quantity.quantity.counter').text(responseData.quantityTotal);
        } else {
          $('.add-to-cart-eror').text(responseData.message);
        }
        document.getElementById('main-overlay').style.display = 'none';
        $.spinner().stop();
      },
      error: function () {
        document.getElementById('main-overlay').style.display = 'none';
        $.spinner().stop();
      },
    });
  }
});

/**
 * Function to update minicart content
 * @param updateMiniCartUrl endpoint for minicart content
 */
 const updateMiniCart = (updateMiniCartUrl) => {
  $.spinner().start();
  $.ajax({
    url: updateMiniCartUrl,
    method: 'GET',
    success: function (responseData) {
      if (responseData && !responseData.error) {
        $('.js-dropdown-container.minicart-modal').html(responseData);
      } else {
        $('.minicart-action').trigger('click');
      }
      let eventName = 'layer-minicart:openRemote';
      $('body').trigger(eventName);
      $('#minicart-quantity-number')[0].innerText = $('.items-in-cart')[0].innerText;



      if (window.isMobile()){
        var root = document.documentElement;
        let modalHeader = 22;
        let modalFooter = $('.minicart-modal .modal-footer')[0].clientHeight;
        let totalHeight = modalHeader + 32 + modalFooter;

          let windowHeight = window.innerHeight;
          let heightContent = 'calc(100svh - ' + totalHeight + 'px)';
          if ($('.minicart-modal .product-content').length > 0) {
            $('.minicart-modal .product-content')[0].setAttribute("style", "max-height: " + heightContent + "");
          }
          let a = $('#layer-minicart')[0].clientHeight;
          $('.layer-minicart .dropdown-content')[0].style.transition = 'none';
          root.style.setProperty('--slide-open-mobile', 'calc(100svh - ' + a + 'px)');
          setTimeout(() => {
            $('.layer-minicart .dropdown-content')[0].style.transition = '';
          }, 50);



      }
    },
    error: function () {
      $('.minicart-action').trigger('click');
      $.spinner().stop();
    },
  });
};


function loadScript(url) {
  document.querySelectorAll('.paypal-cart-button').forEach((button) => button.innerHTML = '');
  let script = document.createElement('script');
  script.src = url;
  document.body.appendChild(script);
}

$(document).on('layer-minicart:openRemote', function (e) {
  if(document.getElementsByClassName('minicart-paypal-button-container').length > 0) {
    if(document.getElementById('cart-paypal-button-container')) {
      let prepandPaypalminicart = document.querySelector('#cart-paypal-button-container').dataset.prepandpaypalminicart;

      $.ajax({
        url: prepandPaypalminicart,
        method: 'GET',
        success: function (responseData) {
          if (responseData && !responseData.error) {
            let scriptUrl = document.querySelector('#cart-paypal-button-container').dataset.scripturl;
            if ($('script[src="'+scriptUrl+'"]').length > 0) {
              $('script[src="'+scriptUrl+'"]').remove();
            } 
            loadScript(scriptUrl);
           }
        }
      });
      
    }
  }
});

$(document).on('layer-minicart:updateminiCart', function (e) {
  const updateMiniCartUrl = $('#minicart-layer-dropdown')[0].dataset.remoteContent;
  updateMiniCart(updateMiniCartUrl);
});

$(document).on('click', '.minicart-notification-container .minicart-continue-shopping-link', function (e) {
  $('.minicart-notification-container .minicart-nofitication-close').click();
});



$(document).on('DOMNodeInserted', function(e) { 
  if($(e.target).hasClass('paypal-checkout-sandbox')){
    if(document.activeElement.parentElement.parentElement.classList.contains('paypal-cart-button-minicart')){
        fastCheckout('paypal', 'minicart');
    }
  }
});


$('body').on('click', '.apple-pay-minicart-button', function() {
  fastCheckout('applepay', 'minicart');
});


function fastCheckout(cartType, buttonPosition) {
  var fastCheckoutUrl = $(".track-fast-checkout-url").val();
  var fastCheckoutData = cartType + '_fast_' + buttonPosition;

  if(fastCheckoutUrl) {
    $.ajax({
      url: fastCheckoutUrl,
      method: 'GET',
      dataType: 'json',
      data: { fastPayment: fastCheckoutData},
      success: function (data) {
        $('body').trigger('checkout:fastCheckoutStart', [cartType, buttonPosition]);
      }
  });
 }
}