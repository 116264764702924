const headerSearchComponent = document.getElementById('header-serach-component');

let scrollDistance = 0;
let closeSize = 0;
var root = document.documentElement;
$('.js-main-search').on('click', () => {
  if (closeSize == 0) {
    closeSize = $('.header-search .search-input .icon--close-mini-icon')[0].clientWidth;
    root.style.setProperty('--searchClose-width', closeSize + 16 + "px");
  }
  let headerHeight = $('header')[0].clientHeight.toString() + 'px';
  document.documentElement.style.setProperty('--header-height', headerHeight);
  headerSearchComponent.style.display = 'block';
  $('.wrapper-nav').addClass('menu-blocked');
  if (!window.isMobile()) {
    document.querySelectorAll('.blur-body-overlay')[0].classList.add('active');
  }
  $('.header-search').addClass('is-open');
  $('.header-search').removeClass('is-closed');
  $('body').trigger('search:open');
  scrollDistance = window.pageYOffset;
  $('body')[0].setAttribute("style", "overflow-y: hidden;position:fixed;top: -"+ scrollDistance +"px;")
  const minicart = document.querySelector('.layer-minicart');
  minicart.dataset.lastoffset = scrollDistance;
  if ($('.header-search .einstein-carousel').length == 0) {
    $('.header-search').addClass('no-recommender-available');
  }

  !window.isMobile() ? $('input.search-field').trigger('focus') : '';
  var height = $('.suggestions-wrapper')[0].clientHeight;
  var total = height + 70;
  document.documentElement.style.setProperty('--min-rec-height', total.toString() + 'px');
  window.dispatchEvent(new Event('resize'));
  $('.suggestions-wrapper').addClass('animation');
});

$('#search-bar-close').on('click', () => {
  $('.suggestions-wrapper').addClass('animation');
  $('.wrapper-nav').removeClass('menu-blocked');
  if (window.isMobile()) {
    $('header').removeClass('header-unpin');
  }
  $('.header-search').removeClass('is-open');
  $('.header-search').addClass('is-closed');

    // $('body')[0].style.overflow = 'visible';
    $('body')[0].style['overflow-y'] = 'visible';
    $('body')[0].style['position'] = 'unset';
    $('body')[0].style['top'] = '0';
    const minicart = document.querySelector('.layer-minicart').dataset.lastoffset;

    // // Set the window's scroll position back to what it was before the modal was opened
    window.scrollTo(0, scrollDistance);
  setTimeout(function() {
    if (!window.isMobile()) {
      document.querySelectorAll('.blur-body-overlay')[0].classList.remove('active');
    }
    $('.header-search').removeClass('is-closed');
    $('.suggestions-wrapper').removeClass('animation');
  }, 500);

  // setTimeout(function() {
  //   headerSearchComponent.style.display = 'none';
  // }, 1000);
  $('.js-main-search').trigger('focus');
});


$('.header-search .form-control.search-field').on('click', () => {
  if (window.isMobile() && !$('.header-search').hasClass('is-open')) {
    $('.suggestions-wrapper').addClass('animation');
    if (closeSize == 0) {
      closeSize = $('.header-search .search-input .icon--close-mini-icon')[0].clientWidth;
      root.style.setProperty('--searchClose-width', closeSize + 16 + "px");
    }
    let headerHeight = $('header')[0].clientHeight.toString() + 'px';
    document.documentElement.style.setProperty('--header-height', headerHeight);
    headerSearchComponent.style.display = 'block';
    $('.wrapper-nav').addClass('menu-blocked');
    if (!window.isMobile()) {
      document.querySelectorAll('.blur-body-overlay')[0].classList.add('active');
    }
    !window.isMobile() ? $('input.search-field').trigger('focus') : '';
    if (window.isMobile()) {
      $('header').addClass('header-unpin');
    }
    $('.header-search').addClass('is-open');
    $('.header-search').removeClass('is-closed');
    $('body').trigger('search:open');
    scrollDistance = window.pageYOffset;
    $('body')[0].setAttribute("style", "overflow-y: hidden;position:fixed;top: -"+ scrollDistance +"px;")
    const minicart = document.querySelector('.layer-minicart');
    minicart.dataset.lastoffset = scrollDistance;
    if ($('.header-search .einstein-carousel').length == 0) {
      $('.header-search').addClass('no-recommender-available');
    }
    var height = $('.suggestions-wrapper')[0].clientHeight;
    var total = height + 70;
    document.documentElement.style.setProperty('--min-rec-height', total.toString() + 'px');
    window.dispatchEvent(new Event('resize'));
  }
});