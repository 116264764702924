document.body.addEventListener('benefit-bar-carousel:init', function () {
  let elementList = document.querySelector('.content-central-benefit-bar');
  let triggerElementList = document.querySelectorAll('.trigger-description-notification');

  if (elementList) {
    elementList.addEventListener('click', function () {
      triggerElementList.forEach(function (item) {
        item.classList.toggle('opened');
      });
    });
  }

  // Marni Gift Experience Survey container height calc
  const surveyBody = document.getElementById('survey-start');
  if (surveyBody) {
    const isMobile = () => window.innerWidth < 992;
    const landingContainer = document.querySelector('.surveylanding-container');
  
    if(landingContainer && !isMobile()) {
      const mainHeader = document.querySelector('header.header');
      let imgContainer = landingContainer.querySelector('.img-container');
      let actionContainer = landingContainer.querySelector('.actions-container');
    
      let headerHeight = mainHeader.clientHeight;
      imgContainer.setAttribute('style', 'max-height: '+(window.innerHeight-headerHeight)+'px');
      actionContainer.setAttribute('style', 'max-height: '+(window.innerHeight-headerHeight)+'px');
    }
  }
});
