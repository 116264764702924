$(document).ready(() => {
  let wrapper = document.querySelector('.account-section-wrapper');
  if (wrapper && wrapper.dataset.section) {
    const elementId = wrapper.dataset.section;
    if (document.getElementById(elementId)) {
      document.getElementById(elementId).parentElement.classList.add('active');
    }
  }
});

$('.layer-logged > button').on('click', () => {
  document.getElementById('main-overlay').style.display = 'block';
});

$('.toggle-order-accordion').on('click', (e) => {
  const parentClass = e.target.parentElement.classList[3];
  const orderAccordion = document.querySelector('.order-accordion-content.' + parentClass);
  const icon = e.target;
  if (orderAccordion.classList.contains('is-open')) {
    orderAccordion.classList.remove('is-open');
    icon.classList.remove('active');
  } else {
    orderAccordion.classList.add('is-open');
    icon.classList.add('active');
  }
});
