const isMargiela = document.querySelector('body').classList.contains('Margiela');
/**
 * Validates the attributes
 * @param {jQuery} $productContainer - DOM container for a given product
 * @returns {boolean} - [true] when valid, [false] when invalid
 */
function validation($productContainer, trigger) {
  var $attributes;
  let invalidAttributesSelect = [];
  var isNewPDP = false;
  var isPrivateSales = $('.private-sales').data('isPrivateSalesProduct');
  var isLogged = $('.private-sales').data('isloggedin');

  if ($('.pdp-new').length > 0) {
    isNewPDP = true;
  }

  if (isNewPDP & isPrivateSales && !isLogged) {
    return true;
  }

  if (!isNewPDP) {
    $attributes = $productContainer.find('.variation-attribute select[class*="select-"]');
    $attributes.each(function (a) {
      let optionSelected = $(this).find('option:selected');
      if (!optionSelected || !optionSelected.data('attrValue') || optionSelected.prop('disabled')) {
        invalidAttributesSelect.push($(this).parent());
      }
    });
  } else {
    $attributes = $productContainer.find('.variation-attribute');
    $attributes.each(function (a) {
      let availableAttributes = $(this).find('.attribute-selector-new');
        availableAttributes.each(function (a) {
          if (!availableAttributes.hasClass('selected')) {
            invalidAttributesSelect.push($(this));
          }
        });
    });
  }

  if (invalidAttributesSelect.length > 0) {
    invalidAttributesSelect.forEach(function (a) {

      if(isMargiela){
        a.find('select').focus();
      } else if (!isNewPDP){
        a.addClass('invalid');
      }

      
    });

    if (isNewPDP && $('.layer-product-notify.is-open').length < 1 && $('.layer-product-coming-soon.is-open').length < 1) {
      $('.variation-size-action').click();
    }

    return false;
  }

  $attributes.removeClass('invalid');
  return true;
}

module.exports = validation;
