const isMargiela = document.querySelector('body').classList.contains('Margiela');
const isJilSander = document.body.dataset.sitebrand === "JilSander";
const isMarni = document.body.dataset.sitebrand === "Marni";

let bodyMeasurementSwitchButtons;
let productMeasurementSwitchButtons;

function columnAndRowHighlight () {
  const tds = document.querySelectorAll('.modal-content-gudielines .size-guidelines-types-item .table tbody tr td');
  // const trs = document.querySelectorAll('.modal-content-gudielines .size-guidelines-types-item .table tbody tr');
  const ths = document.querySelectorAll('.modal-content-gudielines .size-guidelines-types-item .table thead tr th');
  [...tds, ...ths].forEach((td, i) => {
    td.addEventListener('mouseover', (e) => {
      const currentRow = e.target.closest('tr');
      currentRow.classList.add('hightlighet-table-element');
      const currentRowHeight = currentRow.offsetHeight;
      currentRow.style.height = currentRowHeight + 'px';
      const newIndex = [...e.target.parentElement.children].indexOf(e.target);
      const trsToBeHighLighted = e.target.closest('table').querySelectorAll(`tr > :nth-child(${newIndex + 1})`);
      trsToBeHighLighted.forEach(el => el.classList.add('hightlighet-table-element'));
      const tbodyHighLightedCells = document.querySelectorAll('.modal-content-gudielines tr .hightlighet-table-element');
      addRemoveTbodyLightedCellClass(tbodyHighLightedCells, 'add');
    });
    td.addEventListener('mouseleave', (e) => {
      const currentRow = e.target.closest('tr');
      currentRow.classList.remove('hightlighet-table-element');
      const newIndex = [...e.target.parentElement.children].indexOf(e.target);
      const trsToBeHighLighted = e.target.closest('table').querySelectorAll(`tr > :nth-child(${newIndex + 1})`);
      trsToBeHighLighted.forEach(el => el.classList.remove('hightlighet-table-element'));
      const tbodyHighLightedCells = document.querySelectorAll('.modal-content-gudielines tr .hightlighet-table-element');
      addRemoveTbodyLightedCellClass(tbodyHighLightedCells, 'remove');
    });
  });
}


// get first and last elements from tbody
function addRemoveTbodyLightedCellClass(tbodyHighLightedCells, act) {
  tbodyHighLightedCells.forEach((cell, index) => {
    if(act === 'remove') {
      document.querySelectorAll('.first-highlighted') && document.querySelectorAll('.first-highlighted').forEach(el => {
        el.classList.remove('first-highlighted');
      });
      document.querySelectorAll('.last-highlighted') && document.querySelectorAll('.last-highlighted').forEach(el => {
        el.classList.remove('last-highlighted');
      });
    } else {
      if(index === 0){
        cell.classList.add('first-highlighted');
      } else if(index === tbodyHighLightedCells.length -1) {
        cell.classList.add('last-highlighted');
      }
    }
  });
}

function hideShowUnits(unit) {
  const modal = document.querySelector('#size-guidelines-modal');
  const displayValues = [...modal.querySelectorAll('.js-value')];
  displayValues.forEach((item) => {
    if (item.classList.contains(`js-${unit}`)) {
      item.classList.remove('d-none');
    } else {
      item.classList.add('d-none');
    }
  });
}

function sizeUnitToggler() {
  const modal = document.querySelector('#size-guidelines-modal');
  if (modal) {
    const muTogglers = [...modal.querySelectorAll('input[name="measuringguide_mu"]')];
    muTogglers.forEach((item) => {
      let unit = item.checked ? 'in' : 'cm';
      hideShowUnits(unit);
      item.addEventListener('change', (e) => {
        unit = e.currentTarget.checked ? 'in' : 'cm';
        hideShowUnits(unit);
        muTogglers.forEach((toggler) => {
          toggler.checked = e.currentTarget.checked;
        });
      });
    });
  }
}

document.querySelectorAll('.fitguide__tab-button').forEach((item) => {
  item.addEventListener('click', (event) => {
    if (item.dataset.ref === 'fitguide-content-01') {
      document.getElementById('fitguide-content-01').style.display = 'block';
      document.getElementById('fitguide-content-02').style.display = 'none';
    }
    if (item.dataset.ref === 'fitguide-content-02') {
      document.getElementById('fitguide-content-01').style.display = 'none';
      document.getElementById('fitguide-content-02').style.display = 'block';
    }
  });
});

if (document.querySelector('.click-to-convert-sizes-text')) {
  document.querySelector('.click-to-convert-sizes-text').addEventListener('click', (event) => {
    document.getElementById('fitguide-content-01').style.display = 'none';
    document.getElementById('fitguide-content-02').style.display = 'block';
    document.getElementById('fitguide-measurement-button').classList.remove('fitguide__tab-button--active');
    document.getElementById('fitguide-sizes-button').classList.add('fitguide__tab-button--active');
  });
}


if (document.querySelector('.container-size-guidelines .modal-header .back-button')) {
  document.querySelector('.container-size-guidelines .modal-header .back-button').addEventListener('click', (event) => {
    document.getElementById('fitguide-content-01').style.display = 'block';
    document.querySelector('.container-size-guidelines .modal-header .title').style.display = 'block';
    document.querySelector('.container-size-guidelines .modal-header .back-button').style.display = 'none';
    document.getElementById('fitguide-content-02').style.display = 'none';
    document.getElementById('fitguide-measurement-button').classList.add('fitguide__tab-button--active');
    document.getElementById('fitguide-sizes-button').classList.remove('fitguide__tab-button--active');
  });
}

if (document.querySelector('.container-size-guidelines .switch-to-table')) {
  document.querySelector('.container-size-guidelines .switch-to-table').addEventListener('click', (event) => {
    document.getElementById('fitguide-content-01').style.display = 'none';
    document.querySelector('.container-size-guidelines .modal-header .title').style.display = 'none';
    document.querySelector('.container-size-guidelines .modal-header .back-button').style.display = 'block';
    document.getElementById('fitguide-content-02').style.display = 'block';
    document.getElementById('fitguide-measurement-button').classList.remove('fitguide__tab-button--active');
    document.getElementById('fitguide-sizes-button').classList.add('fitguide__tab-button--active');
  });
}

if (document.querySelector('.click-to-convert-sizes-text')) {
  document.querySelector('.click-to-convert-sizes-text').addEventListener('click', (event) => {
    document.getElementById('fitguide-content-01').style.display = 'none';
    document.getElementById('fitguide-content-02').style.display = 'block';
    document.querySelector('.container-size-guidelines .modal-header .title').style.display = 'none';
    document.querySelector('.container-size-guidelines .modal-header .back-button').style.display = 'block';
    document.getElementById('fitguide-measurement-button').classList.remove('fitguide__tab-button--active');
    document.getElementById('fitguide-sizes-button').classList.add('fitguide__tab-button--active');
  });
}

document.querySelectorAll('.fitguide__tab-button').forEach((item) => {
  const container = document.querySelector('#size-guidelines-modal .modal-content');
  if (container) {
    let tabs = [...container.querySelectorAll('.fitguide__tab-button')];
    tabs.forEach((items) => {
      items.addEventListener('click', (event) => {
        const thisItem = event.currentTarget;
        if (!thisItem.classList.contains('fitguide__tab-button--active')) {
          [...container.querySelectorAll('.fitguide__tab-button--active')].forEach((item) => {
            item.classList.remove('fitguide__tab-button--active');
          });
          [...container.querySelectorAll('.fitguide__content')].forEach((item) => {
            item.classList.remove('fitguide__content--active');
          });
          thisItem.classList.add('fitguide__tab-button--active');
          if(!isMargiela) {
            container.querySelector(`#${thisItem.dataset.ref}`) && container.querySelector(`#${thisItem.dataset.ref}`).classList.add('fitguide__content--active');
          }
          [...container.querySelectorAll(`[data-ref="${thisItem.dataset.ref}"]`)].forEach((item) => {
            item.classList.add('fitguide__tab-button--active');
          });
        }
      });
    });
    const sizeSelectors = [...container.querySelectorAll('.measuring-guide__size-selector select')];
    sizeSelectors.forEach((item) => {
      item.addEventListener('change', (e) => {
        const [type, size] = e.currentTarget.value.split('_');
        let lengthValue = size;
        if (type === 'size') {
          container.querySelector(`.measuring-guide__interactive-table[data-measure="${type}"]:not(.d-none)`).classList.add('d-none');
          container.querySelector(`[data-measure="${type}"][data-size="${size}"]`).classList.remove('d-none');
          const lenghtSelect = container.querySelector('.measuring-guide__size-selector select[name="length"]');
          if (lenghtSelect) {
            lengthValue = lenghtSelect.value.split('_')[1];
          }
        }
        [...container.querySelectorAll(`.measuring-guide__interactive-table:not(.d-none) [data-length]:not([data-length="${lengthValue}"])`)].forEach((item) => {
          item.classList.add('d-none');
        });
        container.querySelector(`.measuring-guide__interactive-table:not(.d-none) [data-length="${lengthValue}"]`).classList.remove('d-none');
      });
    });
  }
});

function enhancedMeasuringGuide() {
  sizeUnitToggler();
  const container = document.querySelector('#size-guidelines-modal .modal-content');
  if (container) {
    let tabs = [...container.querySelectorAll('.fitguide__tab-button')];
    tabs.forEach((items) => {
      items.addEventListener('click', (event) => {
        const thisItem = event.currentTarget;
        if (!thisItem.classList.contains('fitguide__tab-button--active')) {
          [...container.querySelectorAll('.fitguide__tab-button--active')].forEach((item) => {
            item.classList.remove('fitguide__tab-button--active');
          });
          [...container.querySelectorAll('.fitguide__content')].forEach((item) => {
            item.classList.remove('fitguide__content--active');
          });
          thisItem.classList.add('fitguide__tab-button--active');
          if(!isMargiela) {
            container.querySelector(`#${thisItem.dataset.ref}`) && container.querySelector(`#${thisItem.dataset.ref}`).classList.add('fitguide__content--active');
          }
          [...container.querySelectorAll(`[data-ref="${thisItem.dataset.ref}"]`)].forEach((item) => {
            item.classList.add('fitguide__tab-button--active');
          });
        }
      });
    });
    const sizeSelectors = [...container.querySelectorAll('.measuring-guide__size-selector select')];
    sizeSelectors.forEach((item) => {
      item.addEventListener('change', (e) => {
        const [type, size] = e.currentTarget.value.split('_');
        let lengthValue = size;
        if (type === 'size') {
          container.querySelector(`.measuring-guide__interactive-table[data-measure="${type}"]:not(.d-none)`).classList.add('d-none');
          container.querySelector(`[data-measure="${type}"][data-size="${size}"]`).classList.remove('d-none');
          const lenghtSelect = container.querySelector('.measuring-guide__size-selector select[name="length"]');
          if (lenghtSelect) {
            lengthValue = lenghtSelect.value.split('_')[1];
          }
        }
        [...container.querySelectorAll(`.measuring-guide__interactive-table:not(.d-none) [data-length]:not([data-length="${lengthValue}"])`)].forEach((item) => {
          item.classList.add('d-none');
        });
        container.querySelector(`.measuring-guide__interactive-table:not(.d-none) [data-length="${lengthValue}"]`).classList.remove('d-none');
      });
    });
  }
}

function basicMeasuringGuide() {
  sizeUnitToggler();
}

function buildSingleRowContent(measure) {
  let content = '';
  if(measure && measure.values) {
    measure.values.forEach( val => {
      content += `
        <td class="js-values">
          ${val.cm ? `<span class="js-value js-cm">${val.cm}</span>` : null}
          ${val.in ? `<span class="js-value js-in d-none">${val.in}</span>` : null}
        </td>
      `
    })
  }
  return content;
}

const calcDynamicPercentWidth = (ref, length) => ``;

function buildTableHeader(bodyMeasurement) {
  let headings = '';
  if(bodyMeasurement && bodyMeasurement.headings) {
    bodyMeasurement.headings.forEach((el, index) => {
      headings += `<th style="width: ${calcDynamicPercentWidth(100, bodyMeasurement.headings.length)}" class="${index === 1 ? 'fixed-side' : ''}">${el}</th>`;
    });
  }
  return headings;
}

function buildTableBody(bodyMeasurement) {
  let content = '';
  if(bodyMeasurement && bodyMeasurement.convertedMeasurements) {
    bodyMeasurement.convertedMeasurements.forEach((measure) => {
      content += `
        <tr>
          <td class="measure-label label fixed-side">${measure.label}</td>
          ${buildSingleRowContent(measure)}
        </tr>
      `
    })
  }
  return content;
}

const initUnitSizeChangeBehaviour = (unitSizeInputs) => {
  const toggleActiveButton = (input) => {
    unitSizeInputs && unitSizeInputs.forEach(b => b.classList.remove('-active'));
    input.target.closest('.jils-change-unit-measure').classList.add('-active');
  }
  unitSizeInputs ? unitSizeInputs.forEach(b => b.addEventListener('click', (e) => toggleActiveButton(e))) : null;
}


const bodyMeasurementTemplate = (bodyMeasurement) => {
  const changeUnitMeasureLabel = `<div class="change-unit-measure-label">${bodyMeasurement && bodyMeasurement.measurementsDescriptionLabel}</div>`;
  const changeUnitMeasureSeparator = `<div class="change-unit-measure-separator">/</div>`;
  return `
    <header class="measuring-guide__header">
      <div class="measuring-guide__header-content">
        <p class="text-measurements-guide">${bodyMeasurement.description}</p>    
      </div>
      <div class="measuring-guide__toggler">
        <div class="col">
          <div class="row choose-size-radio-input-row">
            <div class="col-auto measuring-guide__radio-input -jils-measuring-guide-input">
              <div class="form-group mm-switch -md" id="body-measurements-switch">
              ${isJilSander ? changeUnitMeasureLabel : ''}
                <div class="radio mm-switch__toggle jils-change-unit-measure -active">
                  <label for="unit-measure_0">cm
                    <input name="unit-measure" class="body-unit-measure unit-measure" id="unit-measure_0" type="radio" value="cm" checked="">
                  </label>
                </div>
                ${isJilSander ? changeUnitMeasureSeparator : ''}
                <div class="radio mm-switch__toggle jils-change-unit-measure">
                  <label for="unit-measure_1">inches
                    <input name="unit-measure" class="body-unit-measure unit-measure" id="unit-measure_1" type="radio" value="in">
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="measuring-guide-content row">
      <div class="col-12">
        <div class="table table-wrapper">
          <table class="table table-striped body-measure-table">
            <thead>
              ${buildTableHeader(bodyMeasurement)}
            </thead>
            <tbody>
              ${buildTableBody(bodyMeasurement)}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  `
}

const toggleButtonClick = (btn) => {
  if(btn) {
    btn.addEventListener('click', (e) => {
      e.preventDefault();

      let selectedUnitMeasure = e.target && e.target.classList.contains('unit-measure') ? e.target.value : e.target.querySelector('.unit-measure').value;
      let selectedFitGuideSection = e.target.closest('.form-group.mm-switch').getAttribute('id');
      const isBodyMeasurementSection = selectedFitGuideSection === 'body-measurements-switch';
      const isProductMeasurementSection = selectedFitGuideSection === 'product-measurements-switch';

      if(isProductMeasurementSection) {
        productMeasurementSwitchButtons.forEach(b => b.classList.remove('-active'));
      } else if(isBodyMeasurementSection) {
        bodyMeasurementSwitchButtons.forEach(b => b.classList.remove('-active'));
      }
      
      e.target.closest('.mm-switch__toggle').classList.add('-active');

      if(selectedUnitMeasure) {
        if(selectedUnitMeasure == 'in') {
          if(isBodyMeasurementSection) {
            document.querySelectorAll('.body-measure-table tbody .js-values .js-cm').forEach(cms => {
              cms.classList.contains('d-none') ? null : cms.classList.add('d-none');
            });
            document.querySelectorAll('.body-measure-table tbody .js-values .js-in').forEach(ins => {
              ins.classList.remove('d-none');
            });
          } else if (isProductMeasurementSection) {
            document.querySelectorAll('.single-detail-content .table-details-value ._measures-cm').forEach(cms => {
              cms.classList.contains('d-none') ? null : cms.classList.add('d-none');
            });
            document.querySelectorAll('.single-detail-content .table-details-value ._measures-in').forEach(ins => {
              ins.classList.remove('d-none');
            });
          }
        } else {
          if(isBodyMeasurementSection) {
            document.querySelectorAll('.body-measure-table tbody .js-values .js-in').forEach(ins => {
              ins.classList.contains('d-none') ? null : ins.classList.add('d-none');
            });
            document.querySelectorAll('.body-measure-table tbody .js-values .js-cm').forEach(cms => {
              cms.classList.remove('d-none');
            });
          } else if (isProductMeasurementSection) {
            document.querySelectorAll('.single-detail-content .table-details-value ._measures-in').forEach(ins => {
              ins.classList.contains('d-none') ? null : ins.classList.add('d-none');
            });
            document.querySelectorAll('.single-detail-content .table-details-value ._measures-cm').forEach(cms => {
              cms.classList.remove('d-none');
            });
          }
        }
      }
    })
  }

}


module.exports = {
  sizeChart: function () {
    $('#size-guidelines-modal').on('show.bs.modal', function (e) {
      const layerBody = e.currentTarget.querySelector('.modal-content');
      const triggerButton = document.querySelector('[data-target="#size-guidelines-modal"]');
      layerBody.innerHTML = '';
      $.ajax({
        url: triggerButton.getAttribute('href'),
        type: 'get',
        dataType: 'json',
        beforeSend: () => {
          $.spinner().start();
        },
        success: function (data) {
          if (data.success) {
            layerBody.innerHTML = data.content;
            if (layerBody.querySelector('.fitguide__title')) {
              enhancedMeasuringGuide();
            } else {
              basicMeasuringGuide();
            }
          }
          $.spinner().stop();
        },
        error: (error) => {
          $.spinner().stop();
          layerBody.innerHTML = `<p class="error">${error.responseJSON.message}</p>`;
        },
      });
    });
    $('#sizeChartModal').on('show.bs.modal', function (e) {
      const layerBody = e.currentTarget.querySelector('.modal-content');
      const triggerButton = document.querySelector('[data-target="#sizeChartModal"]');
      layerBody.innerHTML = '';
      $.ajax({
        url: triggerButton.getAttribute('href'),
        type: 'get',
        dataType: 'json',
        beforeSend: () => {
          $.spinner().start();
        },
        success: function (data) {
          if (data.success) {
            layerBody.innerHTML = data.content;
          }
          $.spinner().stop();
        },
        error: (error) => {
          $.spinner().stop();
          layerBody.innerHTML = `<p class="error">${error.responseJSON.message}</p>`;
        },
      });
    });
  },
  updateFitGuideUnit: function () {
    $("input[name='unit-measure']").on('click', function (e) {
      let sizes = $('.measuring-guide__size-table').find('.table-details-value');
      $('span[class^="_measures-"]').addClass('d-none');
      for (let i = 0; i < sizes.length; i++) {
        let sizeMeasures = $(sizes[i]).find('span[class^="_measures-"]');
        for (let el = 0; el < sizeMeasures.length; el++) {
          if ($(sizeMeasures[el]).hasClass('_measures-' + e.currentTarget.value.toLowerCase())) {
            $(sizeMeasures[el]).removeClass('d-none');
          }
        }
      }
    });
  },
  updateFitGuideSize: function () {
    $("select[name='choose-your-size']").on('change', function (e) {
      let sizes = $('.measuring-guide__size-table').find('.table-details-value');
      for (let i = 0; i < sizes.length; i++) {
        if ($(sizes[i]).hasClass('fitguide-size-' + e.currentTarget.value.toLowerCase())) {
          $(sizes[i]).removeClass('d-none');
        } else {
          $(sizes[i]).addClass('d-none');
        }
      }
    });
  },
  openFitGuide: function () {
    $('.size-guidelines-button').on('click', function (e) {
      let url = e.currentTarget.dataset['getcontent'];
      if (url) {
        $.ajax({
          url: url,
          type: 'get',
          dataType: 'json',
          success: function (data) {
            const sizeConversionAccordion = document.querySelector('.accordion.size-conversion-accordion');
            const bodyMeasuresAccordion = document.querySelector('.accordion.body-measures-accordion');
            const productMeasuresAccordion = document.querySelector('.accordion.product-measures-accordion');
            const productCategorySizechart =  document.querySelector('.accordion.product-category-sizechart-accordion');
            const genderSizeConversionAccordion = document.querySelector('.accordion.gender-size-conversion-accordion');
            const isDynamicFitguideElement = isMargiela || isJilSander;
            if(isMargiela) {
              $('ul.size-guidelines-types li').addClass('d-none');
            }

            if (data) {
              $('#unit-measure_0').trigger('click');
              if (data.sizeListImageUrl) {
                $('.measuring-img__sketches')
                  .empty()
                  .append('<img src="' + data.sizeListImageUrl + '" alt="img" class="single-image-product" />');
              }
              if (data.sizeListOptions == "<option value=\"uni\">UNI</option>") {
                  $('.fitguide__container--desktop').addClass('size-uni-active');
              }
              if (data.sizeListOptions) {
                $("select[name='choose-your-size']")
                  .empty()
                  .append(data.sizeListOptions);
              }
              if (data.tableDetails) {
                $('.measuring-guide__size-table .row')
                  .empty()
                  .append(data.tableDetails);
                $('#fitguide-content-01')
                  .css('display' , 'block');
                if(isMarni && $('#fitguide-measurement-button').length > 0) {
                  $('#fitguide-measurement-button')
                  .trigger('click');
                }
                $('#fitguide-measurement-button')
                  .addClass('fitguide__tab-button--active')
                  .closest('.tab-button')
                  .css('display' , 'flex');

                let selectedSizePDP = $('.attribute-size .fsb-button').length ? $('.attribute-size .fsb-button').text().replace(/&nbsp;/g, '').toLowerCase() : '';
                if(window.isMobile() && isMarni) {
                  selectedSizePDP = $('select.select-size').length ? $('select.select-size').find(":selected").text().replace(/\s+/g, '').toLowerCase() : '';
                } 
                if(selectedSizePDP !== '' && $("#choose-your-size option[value='" + selectedSizePDP + "']").length > 0) {
                  $("select[name='choose-your-size']")
                  .val(selectedSizePDP)
                  .trigger('change');
                }
                  
                if (!data.fitGuideContentAsset) {
                  $('#fitguide-measurement-button')
                    .closest('.tab-button')
                    .siblings('.empty-col')
                    .addClass('col-md-12')
                    .css('border-left' , '0');
                  $('.click-to-convert-sizes')
                    .css('display' , 'none');
                }
              }
              if (data.fitGuideContentAsset) {
                isDynamicFitguideElement && sizeConversionAccordion ? sizeConversionAccordion.classList.remove('d-none') : null;
                if(isMargiela) {
                  $('.size-conversion-accordion .measuring-guide-container')
                  .empty()
                  .append(data.fitGuideContentAsset);
                } else {
                  $('.measuring-guide-container')
                  .empty()
                  .append(data.fitGuideContentAsset);
                }
                $('#fitguide-sizes-button')
                  .closest('.tab-button')   
                  .css('display' , 'flex');
                if (!data.tableDetails) {
                  $('#fitguide-sizes-button')
                    .addClass('fitguide__tab-button--active')
                    .closest('.tab-button')
                    .siblings('.empty-col')
                    .addClass('col-md-10');
                  $('#fitguide-content-02')
                    .css('display' , 'block');
                } 
              }
              if (data.productCategorySizeChartContentAsset) {
                if(isDynamicFitguideElement) {
                  productCategorySizechart ? productCategorySizechart.classList.remove('d-none') : null;
                } 
                $('.product-category-sizechart .measuring-guide-container')
                  .empty()
                  .append(data.productCategorySizeChartContentAsset);
              }

              if (data.genderlessGuideContentAsset) {
                if(isDynamicFitguideElement) {
                  genderSizeConversionAccordion ? genderSizeConversionAccordion.classList.remove('d-none') : null;
                } 
                $('.fitguige-sizes .measuring-guide-container')
                  .empty()
                  .append(data.genderlessGuideContentAsset);
              }
              if(data.bodyMeasurementsData && Object.keys(data.bodyMeasurementsData).length > 0) {
                const bodyMeasurementContent = bodyMeasurementTemplate(data.bodyMeasurementsData);
                if(bodyMeasuresAccordion) {
                  bodyMeasuresAccordion.classList.remove('d-none');
                }
                $('#body-measures .measuring-guide-container').empty()
                .append(bodyMeasurementContent);

                bodyMeasurementSwitchButtons = document.querySelectorAll('.body-measures-accordion .mm-switch .mm-switch__toggle');
                if(isJilSander) {
                  bodyMeasurementSwitchButtons = document.querySelectorAll('.fitguide__content .jils-change-unit-measure');
                  initUnitSizeChangeBehaviour(bodyMeasurementSwitchButtons);
                }
                productMeasurementSwitchButtons = document.querySelectorAll('.product-measures-accordion .mm-switch .mm-switch__toggle');
                bodyMeasurementSwitchButtons.forEach(btn => toggleButtonClick(btn));
                productMeasurementSwitchButtons.forEach(btn => toggleButtonClick(btn));
              }
              if(isDynamicFitguideElement) {
                if (!data.fitGuideContentAsset && !data.genderlessGuideContentAsset && !data.productCategorySizeChartContentAsset && (!data.bodyMeasurementsData || Object.keys(data.bodyMeasurementsData).length == 0)){
                  $('.measuring-guide-container').empty()
                }
                if(Object.keys(data.fitGuide).length > 0) {
                  productMeasuresAccordion ? productMeasuresAccordion.classList.remove('d-none') : null;
                  if(isJilSander) {
                    if(document.querySelectorAll('.fitguide__titles .tab-button:not(.d-none)').length == 1 && productMeasuresAccordion){
                      productMeasuresAccordion.querySelector('button').click();
                    }
                    const productMeasureInput = document.querySelector('#product-measures #interactive-unit-measure_0');
                    productMeasureInput ? productMeasureInput.setAttribute('checked', true) : null;
                  }
                }
                columnAndRowHighlight();
              }
            }
          },
          error: (error) => {
            alert('error');
          },
        });
      }
    });
  },
};

