window.jQuery = window.$ = require('jquery');
window.Swiper = require('swiper8/swiper-bundle');
let processInclude = require('base/util');
const imagesLoaded = require('imagesloaded');
import { gsap } from "gsap/dist/gsap";


$(document).ready(function () {
  const currentPage = document.querySelector('body').getAttribute('id');
  processInclude(require('./errors-maintenance'));
  // processInclude(require('./components/menu'));
  // processInclude(require('./components/consentTracking'));
  // processInclude(require('./components/footer'));
  // processInclude(require('./components/miniCart'));
  // processInclude(require('./components/collapsibleItem'));
  processInclude(require('./components/search'));
  // processInclude(require('./components/clientSideValidation'));
  // processInclude(require('./components/countrySelector'));
  // processInclude(require('./components/toolTip'));
  //processInclude(require('./productDetail'));
  // processInclude(require('./help/helpNavigation'));
  processInclude(require('core/components/benefitBar'));
  processInclude(require('core/login'));
  processInclude(require('core/components/tile'));
  processInclude(require('./account'));
  processInclude(require('./components/cookiePopup'));
  processInclude(require('./components/customSelect'));
  processInclude(require('./headerSearchBar'));
  processInclude(require('./miniCart'));
  processInclude(require('./return/return'));

  //processInclude(require('core/registration'));
  // processInclude(require('marni/search'));
  // processInclude(require('marni/search/search'));
  document.querySelector('.main--layout-page > .error-page-404') ? document.querySelector('.main--layout-page').classList.add('error-404') : null;
  processInclude(require('core/components/wishlist'));
  if (currentPage === 'wishlist-show' || currentPage === 'page-homepage') {
      processInclude(require('tealium/tealiumEvents'));
  }

  $('.product-display-option').on('click', function(){
    var parent = '#' + this.parentElement.id;
    var lastActiveID = $(parent + ' .last-active')[0].id;
    $(parent + ' .last-active').removeClass('-active');
    $(parent + ' .last-active').removeClass('last-active');
    this.classList.add('-active');
    this.classList.add('last-active');
    if (window.isMobile()) {
      var selectedItem = $(this);

      selectedItem[0].scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'center'});
    }
    $(parent + ' #product-display-content-' + lastActiveID)[0].classList.add('hidden');
    $(parent + ' #product-display-content-' + this.id)[0].classList.remove('hidden');
  });

  $('.button-chat-benefit').on('click', function(){
    setTimeout(function(){
    if(window._insideGraph){
      window._insideGraph.doEvent("chatavailable", window.insideFrontInterface.getAvailableAssistants() > 0);
      console.log('powerfront re-init');
      if (typeof (insideFrontInterface) != "undefined" && insideFrontInterface != null && insideFrontInterface.getAvailableAssistants && insideFrontInterface.getAvailableAssistants().length > 0) {
        $('.contact-us-section').removeClass('live-chat-disabled');
        $('.contact-us-section').addClass('live-chat-enabled');
      } else {
        $('.contact-us-section').removeClass('live-chat-enabled');
        $('.contact-us-section').addClass('live-chat-disabled');
      }

      }
    }, 300);
  });

  var lastKnownScrollY = 0;
  var currentScrollY = 0;
  var eleHeader = document.getElementsByTagName('header')[0];
  var eleSearch = document.getElementsByClassName('header-search')[0];
  const classes = {
    pinned: 'header-pin',
    unpinned: 'header-unpin',
  };
  function onScroll() {
    currentScrollY = window.pageYOffset;
    if (lastKnownScrollY == 0) {
      pin();
    } else {
    if (currentScrollY >= eleHeader.clientHeight) {
      if (currentScrollY < lastKnownScrollY) {
        pin();
      } else if (currentScrollY > lastKnownScrollY) {
        unpin();
      }
    } else {
      pin();
    }
    }
    lastKnownScrollY = currentScrollY;
  }
  function pin() {
    if ($('.page-template-homepage').length > 0) {
      eleSearch.classList.remove(classes.unpinned);
      eleSearch.classList.add(classes.pinned);
    } else {
      if (eleHeader.classList.contains(classes.unpinned)) {
        eleHeader.classList.remove(classes.unpinned);
        eleHeader.classList.add(classes.pinned);
        if ($('.category-menu-top-container').length > 0) {
          $('.category-menu-top-container')[0].style.top = eleHeader.clientHeight + 'px';
        }
        if ($('.pd-masonry-grid__header').length > 0) {
          $('.pd-masonry-grid__header')[0].style.top = eleHeader.clientHeight + 'px';
        }
      }
    }
  }
  function unpin() {
    if ($('.page-template-homepage').length > 0) {
      eleSearch.classList.remove(classes.pinned);
      eleSearch.classList.add(classes.unpinned);
    } else {
      if (eleHeader.classList.contains(classes.pinned) || !eleHeader.classList.contains(classes.unpinned)) {
        setTimeout(function() {
        eleHeader.classList.remove(classes.pinned);
        eleHeader.classList.add(classes.unpinned);
        }, 200);
        if ($('.category-menu-top-container').length > 0) {
          $('.category-menu-top-container')[0].style.top = 0;
        }
        if ($('.pd-masonry-grid__header').length > 0) {
          $('.pd-masonry-grid__header')[0].style.top = 0;
        }
      }
    }
  }

  function handleFooterAccordionToggle(clickedElement) {
    const $clickedBox = $(clickedElement).closest('.section-footer-box');
    $('.section-footer-box').each(function() {
        if ($(this).is($clickedBox)) {
            // Do nothing for the clicked box
            return;
        }
        $(this).removeClass('footer-accordeon-expanded').addClass('footer-accordeon-condensed');
    });
    if ($clickedBox.hasClass('footer-accordeon-expanded')) {
        $clickedBox.removeClass('footer-accordeon-expanded').addClass('footer-accordeon-condensed');
    } else {
        $clickedBox.removeClass('footer-accordeon-condensed').addClass('footer-accordeon-expanded');
    }
  }

  $('.button-open-footer').on('click', function() {
    handleFooterAccordionToggle(this);
  });

  const tl = gsap.timeline({ paused: true });
  const imageContainers = document.querySelectorAll(".pd-image-component .image-container-selector");
  const images = document.querySelectorAll(".pd-image-component .image-container-selector img");
  const textBanners = document.querySelectorAll(".experience-layouts-textBanner");
  
  gsap.set(imageContainers, { visibility: "hidden" });
  gsap.set(images, { opacity: 0, transform: "translate(0px, -100%)" });
  gsap.set(textBanners, { visibility: "hidden", opacity: 0, transform: "translate(0px, -100%)" });
  
  tl.to(imageContainers, { visibility: "visible", duration: 0.1 })
    .to(images, { 
      opacity: 1, 
      transform: "translate(0px, 0%)", 
      duration: 1.5, 
      ease: "power4.out", 
      stagger: 0.3 
    })
    .to(textBanners, { 
      visibility: "visible", 
      opacity: 1, 
      transform: "translate(0px, 0%)", 
      duration: 1.5, 
      ease: "power4.out", 
      stagger: 0.3 
    }, "<");
  
  imagesLoaded('.pd-image-component .image-container-selector', function() {
    tl.play();
});

  var root = document.documentElement;
  root.style.setProperty('--navContainer-maxHeight', window.screen.availHeight + "px");
  // set height in a first load
  root.style.setProperty('--navContainer-height', $('header')[0].clientHeight + "px");

  // // set height if a benefit bar is loaded
  const ro = new ResizeObserver(entries => {
    for (let entry of entries) {
      console.log('height changed!' + entry);
      root.style.setProperty('--navContainer-height', $('header')[0].clientHeight + "px");
    }
  });

  ro.observe(document.querySelector('header'));
  document.addEventListener('scroll', onScroll, false);

});
require('base/thirdParty/bootstrap');
require('core/components/spinner');