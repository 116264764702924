'use strict';

const delimiter = ':';
const $body = $('body');
const $document = $(document);

$document.ready(function(){
  $body.trigger('tealiumEvents:loaded');
});

function utagExists(){
    return typeof utag !== 'undefined';
}

/**
* Gets a parameter from a link or from [window.location.href]
*/
function refreshCartData() {
    const url = window.sfcc_urls.refresh_utag_basket;

    if (window.location.href.indexOf('Order-ReturnConfirmation') > 0) {
        return;
    }

    $.ajax({
        url: url,
        method: 'GET',
        dataType: 'json',
        success: function(data) {
            copyResponseData(data);
        },
        error: function(err) {
            console.log('error refreshing utag', err);
        }
    });
}

/**
* Gets a parameter from a link or from [window.location.href]
* @param {string} paramName - the parameter name
* @param {string} url - [window.location.href] or equivalent
* @returns {string} the parameter value or [null]
*/
function getParameterByName(paramName, url = window.location.href) {
    const name = paramName.replace(/[[\]]/g, '\\$&');
    const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

/**
* Get selected variation value by variation id
* @param {Object} variationAttributes - variation attributes model from backend
* @param {string} id - variation id
* @returns {string} the id of the variation value or empty string
*/
function getVariationValue(variationAttributes, id) {
    let result = '';

    if (variationAttributes instanceof Array) {
        const variation = variationAttributes.filter((i) => i.attributeId === id);

        if (variation && variation.length) {
            const selected = variation[0].values.filter((i) => i.selected === true);
            if (selected && selected.length) result = selected[0].id;
        }
    }

    return result;
}

/**
* Use the existing data from the [utag_data] object to fill [utag.data] with product data on PDP
*/
function addProductData() {
    utag.data.page_type = 'product';
    utag.data.product_id = utag_data.product_id;
    utag.data.product_name = utag_data.product_name;
    utag.data.product_season = utag_data.product_season;
    utag.data.product_newin = utag_data.product_newin;
    utag.data.product_category = utag_data.product_category;
    utag.data.product_color = utag_data.product_color;
    utag.data.product_waist = utag_data.product_waist;
    utag.data.product_length = utag_data.product_length;
    utag.data.product_size = utag_data.product_size;
    utag.data.product_unit_price = utag_data.product_unit_price;
    utag.data.product_old_price = utag_data.product_old_price;
    utag.data.product_sale_price = utag_data.product_sale_price;
    utag.data.product_net_price = utag_data.product_net_price;
}

/**
* Use the existing data from the [utag_data] object to fill [utag.data] with product data on Cart/Checkout
*/
function addCartData() {
    utag.data.page_type = 'checkout';
    utag.data.product_id = utag_data.product_id;
    utag.data.product_name = utag_data.product_name;
    utag.data.product_season = utag_data.product_season;
    utag.data.product_newin = utag_data.product_newin;
    utag.data.product_category = utag_data.product_category;
    utag.data.product_color = utag_data.product_color;
    utag.data.product_waist = utag_data.product_waist;
    utag.data.product_length = utag_data.product_length;
    utag.data.product_size = utag_data.product_size;
    utag.data.product_unit_price = utag_data.product_unit_price;
    utag.data.product_old_price = utag_data.product_old_price;
    utag.data.product_sale_price = utag_data.product_sale_price;
    utag.data.product_net_price = utag_data.product_net_price;
    utag.data.product_quantity = utag_data.product_quantity;
    utag.data.product_shipping_type = utag_data.product_shipping_type;
    utag.data.order_currency = utag_data.order_currency;

    if (utag_data.order_track_id) {
        utag.data.order_track_id = utag_data.order_track_id;
    }
}

/**
 * Copies the `utagData` object values to the current utag data object
 * @param {Object} response - the response object from the server that contains a `utagData` object
 */
function copyResponseData(response) {
    if (!response) return;

    for (const key in response.utagData) { // eslint-disable-line no-restricted-syntax
        if (response.utagData.hasOwnProperty(key)) { // eslint-disable-line no-prototype-builtins
            utag.data[key] = response.utagData[key];
        }
    }
}

function concatUtagValues(nodeList, key){
    for (let i = 0; i < nodeList.length; i++) {
        var value = nodeList[i].value;
        var arrayValue = value.replaceAll('[','').replaceAll(']','').replaceAll(' ','').split(',');
        var original = utag.data[key];

        if(original) {
          let mergedArray = original.concat(arrayValue);
          mergedArray = mergedArray.filter((item,index) => {
            return (mergedArray.indexOf(item) == index);
          }); 
          utag.data[key] = mergedArray;
        }
    }
}

function newsletterEventTrigger(gender) {

  if (!gender) gender = '';

  utag.data.page_type = 'newsletter';
  utag.data.page_name = utag_data.store_country + delimiter + 'myaccount' + delimiter + 'newsletter_subscription';
  utag.data.event_name = 'newsletter_subscription_' + gender;
  utag.link(utag.data);
}

module.exports = {
    loadMoreProduct: function(){
        $body.on('product:listing-show-more', function(){
            var utag_product_id = document.querySelectorAll('[name="utag_product_id"]');
            
            concatUtagValues(utag_product_id, 'product_id');
        });
    },

    pageNewsletter: function() {
      $body.on('newsletter:submit', function() {
        if (typeof utag === 'undefined' || !utag) return;
        const $form = $('.page-newsletter .form-newsletter');
        
        $form.find('.gender-check:checked +.checkbox-label').text().trim();
        let genders = [];
        let gender;
        let isMultipleGender = false;
        document.querySelectorAll('.gender-check:checked + .checkbox-label').forEach(g => {
          genders.push(g.innerText);
          isMultipleGender = genders.length > 1;
        });
        gender = isMultipleGender ? 'Both' : genders[0];
        newsletterEventTrigger(gender);
      });
    },

    footerNewsletter: function() {
      $body.on('click', 'footer .form-newsletter [type="submit"]', function() {
        if (typeof utag === 'undefined' || !utag) return;
        const $form = $('footer .form-newsletter');
        const gender = $form.find('[name="gender-radio"]:checked').val(); 
        newsletterEventTrigger(gender);
      });
    },

    video: function() {
        const $video = $('video');

        $video.on('pause', function() {
            if (typeof utag === 'undefined' || !utag) return;

            utag.data.video_title = $(this).find('source').attr('src');
            utag.data.video_time = this.currentTime;
            utag.data.event_name = 'video_pause';
            utag.track('video', utag.data);
        });

        $video.on('loadeddata', function() {
            if (typeof utag === 'undefined' || !utag) return;

            utag.data.video_title = $(this).find('source').attr('src');
            utag.data.video_time = this.duration;
            utag.data.event_name = 'video_open';
            utag.track('video', utag.data);
        });

        $video.on('play', function() {
            if (typeof utag === 'undefined' || !utag) return;

            utag.data.video_title = $(this).find('source').attr('src');
            utag.data.video_time = this.currentTime;

            if (this.currentTime > 0) {
                utag.data.event_name = 'video_play';
            } else {
                utag.data.event_name = 'video_start';
            }

            utag.track('video', utag.data);
        });
    },

    PLPUpdateData: function() {
        // Update data for future events
        $body.on('plp:plpPageLoad', function(e, response) {
            if (typeof utag === 'undefined' || !utag) return;



            var activePLP = 'old';

            if ($('.new-page-search-result').length > 0) {
              activePLP = 'new';
            }

            utag_data.active_plp = activePLP;
        });
    },

    PDPUpdateData: function() {
        // Update data for future events
        $body.on('product:afterAttributeSelect', function(e, response) {
            if (typeof utag === 'undefined' || !utag) return;



            var activePDP = 'old';

            if ($('.pdp-new').length > 0) {
              activePDP = 'new';
            }

            const product = response.data.product;
            utag_data.active_pdp = activePDP;
            utag_data.product_sku = [product.id];
            utag_data.product_color = [getVariationValue(product.variationAttributes, 'color')];
            utag_data.product_waist = [getVariationValue(product.variationAttributes, 'waist')];
            utag_data.product_length = [getVariationValue(product.variationAttributes, 'length')];
            utag_data.product_size = [getVariationValue(product.variationAttributes, 'size')];
        });
    },

    addToCart: function() {
        $body.on('product:afterAddToCart', function(e, response) {
            if (typeof utag === 'undefined' || !utag) return;

            addProductData();



            var activePDP = 'old';

            if ($('.pdp-new').length > 0) {
              activePDP = 'new';
            }
            utag_data.active_pdp = activePDP;

            if (utag_data.page_context_type === 'product') {
                utag.data.event_name = 'pdp_cart_add';
            } else if (response.addAllProductsFromWishList) {
                utag.data.event_name = 'cart_add_wishlist_full';
            } else if (utag_data.page_context_type === 'myaccount') {
                utag.data.event_name = 'cart_add_wishlist_prod';
            } else {
                utag.data.event_name = utag_data.page_type + 'pdp_cart_add';
            }

            utag.link(utag.data);
        });
    },

    backInStock: function() {
        $body.on('product:afterBackInStock', function() {
            if (typeof utag === 'undefined' || !utag) return;

            addProductData();
            var activePDP = 'old';

            if ($('.pdp-new').length > 0) {
              activePDP = 'new';
            }
            utag_data.active_pdp = activePDP;

            utag.data.event_name = 'pdp_backinstock';

            utag.link(utag.data);
        });
    },

    PDPAddToWishlist: function() {
        $body.on('product:afterAddToWishlist', function() {
            if (typeof utag === 'undefined' || !utag || !utag_data.page_context_type === 'product') return;

            addProductData();
            var activePDP = 'old';

            if ($('.pdp-new').length > 0) {
              activePDP = 'new';
            }
            utag_data.active_pdp = activePDP;
            utag.data.event_name = 'pdp_wishlist_add';
            utag.link(utag.data);
        });
    },

    storeLocatorFind: function() {
        $body.on('store:MyLocation', function() {
            if (typeof utag === 'undefined' || !utag) return;

            utag.data.page_type = 'store locator';
            utag.data.page_name = utag.data.page_name + ':my location';
            utag.data.event_name = 'storelocator_mylocation';
            utag.link(utag.data);
        });
    },

    storeLocatorMyLocation: function() {
        $body.on('store:Search', function() {
            if (typeof utag === 'undefined' || !utag) return;

            utag.data.page_type = 'store locator';
            utag.data.page_name = utag.data.page_name + ':search location';
            utag.data.event_name = 'storelocator_search';
            utag.link(utag.data);
        });
    },

    filters: function() {
        $document.on('appliedFilter', function(e) {

            var url;
            if (typeof utag === 'undefined' || !utag) {return;} 
            
            if (typeof e.url !== 'undefined' && e.url) {
              url = e.url;
            };

            if (!url) {
             url = (e.detail && e.detail.url) ? e.detail.url : null; 
            }

            if (!url){
              return;
            }

            const result = [];

            const pmin = getParameterByName('pmin', url);
            const pmax = getParameterByName('pmax', url);

            if (pmin && pmax) {
                result.push('price' + delimiter + pmin + ' to ' + pmax);
            }

            let i = 1;
            let name = getParameterByName('prefn' + i, url);
            let value;
            let subArray;

            while (name) {
                value = getParameterByName('prefv' + i, url);
                name = name.replace('refinement', '');

                if (value.indexOf('|') > -1) { // Multiple values for this preference
                    subArray = value.split('|');

                    for (let j = 0; j < subArray.length; j++) {
                        result.push(name + delimiter + subArray[j]);
                    }
                } else { // Single value for this preference
                    result.push(name + delimiter + value);
                }

                i++;
                name = getParameterByName('prefn' + i, e.url);
            }

            if (result.length > 0) {
                utag.data.filter = result;
                utag.data.event_name = 'filter_usage';
                utag.link(utag.data);
            }
        });
    },

    checkoutStages: function() {
        $body.on('checkout:stageGoTo checkout:updatedCheckoutView', function() {
            if (typeof utag === 'undefined' || !utag) return;

            let step = '';
            const stage = $('#checkout-main').attr('data-checkout-stage');

            if (stage === 'shipping') {
                step = 'step1';
            } else if (stage === 'payment') {
                step = 'step2';
            } else if (stage === 'placeOrder') {
                step = 'step3';
            }

            utag.data.page_name = utag_data.page_name_onload.replace(/step\d/gi, step);
            utag.link(utag.data);
        });
    },

    login: function() {
        $body.on('user:loggedIn', function() {
            if (typeof utag === 'undefined' || !utag) return;
            utag.data.event_name = 'login_ok';
            utag.link(utag.data);
        });
    },

    register: function() {
        $body.on('user:registered', function() {
            if (typeof utag === 'undefined' || !utag) return;

            utag.data.event_name = 'registration_ok';
            utag.link(utag.data);
        });
    },

    fastCheckout: function() {
        $body.on('checkout:fastCheckoutStart', function(event, cartType, buttonPosition) {
          if (typeof utag === 'undefined' || !utag) return;

          if(typeof cartType !== 'undefined' && typeof buttonPosition !== 'undefined') {
            utag.data.cart_type = cartType + ':' + buttonPosition;
          }
          utag.data.event_name = 'fastcheckout_start';
          utag.link(utag.data);
        });
    },

    cartModifyModalOpen: function() {
        $body.on('cart:modifyProductOpen', function() {
            if (typeof utag === 'undefined' || !utag) return;

            addCartData();
            utag.data.event_name = 'cart_prod_update_popup';
            utag.link(utag.data);
        });
    },

    cartModifyModalConfirm: function() {
        $body.on('cart:productModified', function(e, response) {
            if (typeof utag === 'undefined' || !utag) return;

            copyResponseData(response);

            utag.data.page_type = 'checkout';
            utag.data.event_name = 'cart_prod_update_confirm';
            utag.link(utag.data);

            refreshCartData();
        });
    },

    cartRemoveProduct: function() {
        $body.on('cart:productRemoved', function(e, response) {
            if (typeof utag === 'undefined' || !utag) return;

            copyResponseData(response);

            utag.data.page_type = 'checkout';
            utag.data.event_name = 'cart_prod_update';
            utag.link(utag.data);

            refreshCartData();
        });
    },

    cartMoveToPickup: function() {
        $body.on('cart:pickUpItem', function(e, response) {
            if (typeof utag === 'undefined' || !utag) return;

            copyResponseData(response);

            utag.data.page_type = 'checkout';
            utag.data.event_name = 'cart_moveto_pickup';
            utag.link(utag.data);

            refreshCartData();
        });
    },

    cartShipItem: function() {
        $body.on('cart:shipItem', function(e, response) {
            if (typeof utag === 'undefined' || !utag) return;

            copyResponseData(response);

            utag.data.page_type = 'checkout';
            utag.data.event_name = 'cart_moveto_shipping';
            utag.link(utag.data);

            refreshCartData();
        });
    },

    cartStoreDialogOpen: function() {
        $body.on('findInStore:open', function() {
            if (typeof utag === 'undefined' || !utag) return;
            if ($('.page').data('action') !== 'Cart-Show') return;

            addCartData();

            utag.data.page_type = 'checkout';
            utag.data.event_name = 'cart_store_detail_popup';
            utag.link(utag.data);
        });
    },

    cartStoreChanged: function() {
        $body.on('cart:storeChanged', function(e, response) {
            if (typeof utag === 'undefined' || !utag) return;

            copyResponseData(response);

            utag.data.page_type = 'checkout';
            utag.data.event_name = 'cart_store_detail_change';
            utag.link(utag.data);
        });
    },

    helpOrderDetails: function() {
        $body.on('help:orderDetailsOpened', function(e, response) {
            if (typeof utag === 'undefined' || !utag) return;

            copyResponseData(response);

            utag.data.page_name = `${utag.data.store_country}:order_track:detail ${utag.data.order_track_id}`;
            utag.data.page_type = 'order track';
            utag.view(utag.data);
        });
    },

    createReturnSteps: function() {
        $body.on('order:createReturn:step2', function() {
            if (typeof utag === 'undefined' || !utag) return;

            addCartData();

            utag.data.page_name = `${utag.data.store_country}:order_track:return_exchange:step2`;
            utag.data.page_type = 'return-exchange funnel';
            utag.data.event_name = 'return_exchange:step2';
            utag.view(utag.data);
        });
        $body.on('order:createReturn:step3', function(e, params) {
            if (typeof utag === 'undefined' || !utag) return;

            addCartData();
            
            utag.data.product_return_type = params.types;

            utag.data.page_name = `${utag.data.store_country}:order_track:return_exchange:step3`;
            utag.data.page_type = 'return-exchange funnel';
            utag.data.event_name = 'return_exchange:step3';
            utag.view(utag.data);
        });
        $body.on('order:createReturn:thank-you', function(e, params) {
            if (typeof utag === 'undefined' || !utag) return;

            addCartData();
            utag.data.product_return_type = params.types;

            if (!utag.data.order_track_id){
                let searchParams = (new URL(document.location)).searchParams;
                let orderID = searchParams.get('orderID');
                utag.data.order_track_id =  orderID;
            }

            utag.data.page_name = `${utag.data.store_country}:order_track:return_exchange:thank you`;
            utag.data.page_type = 'return-exchange funnel';
            utag.data.event_name = 'return_exchange:thank you';
        });
    },

    /**
     * Method to track events and collect information about customers
     */
    updateCustomerAttributes: function() {
        if (utagExists()) {
            $body.on('customer:updateAttributes', function() {
                const url = $('.get-customer-profile-url').attr('data-get-customer-profile');

                if (url) {
                    $.ajax({
                        url: url,
                        method: 'GET',
                        success: function(data) {
                            if (typeof utag === 'undefined' || !utag || !data.success) return;

                            for (const key in data.customerProfileAttributes) {
                                if (data.customerProfileAttributes.hasOwnProperty(key)) {
                                    utag.data[key] = data.customerProfileAttributes[key];
                                }
                            }

                            utag.link(utag.data);
                            $body.trigger('datalayer:redirectUrl');
                        },
                        error: function(data) {
                            console.error(data);
                        }
                    });
                }
            });
        }
    },
    /**
     * Method to track events and collect information about cart and wishlist
     */
    updateCartAndWishlist: function() {
        if (utagExists()) {
            $(document).ready(function() {
            /* Persist wishlist variable */

              var url = $('.get-datalayer-wishlist-url').attr('data-datalayer-wishlist');

              if (url) {
                  $.ajax({
                        url: url,
                        method: 'GET',
                        success: function(data) {
                            var persist_wishlist = {
                                item: data.item,
                                total_with_vat: data.total_with_vat,
                                currency: data.currency
                            };
                            utag.data['persist_wishlist'] = persist_wishlist;
                        },
                        error: function(data) {
                            console.error(data);
                        }
                  });
              }

              /* Persist cart variable */
              url = $('.get-datalayer-cart-url').attr('data-datalayer-cart');

                if (url) {
                    $.ajax({
                        url: url,
                        method: 'GET',
                        success: function(data) {
                            var persist_cart = {
                                item: data.item,
                                total_without_vat: data.total_without_vat,
                                total_with_vat: data.total_with_vat,
                                currency: data.currency
                            };
                            utag.data['persist_cart'] = persist_cart;

                            jQuery('body').trigger('cart_wishlist_updated');

                        },
                        error: function(data) {
                            console.error(data);
                        }
                    });
                }
            });
        }
    },

    setListViewType: function() {
        $(".js-change-view-toggle").click(function() {
            let viewType = $(".js-change-view-toggle.active").attr('data-layout');

            if (viewType == 'single') {
                utag.data.list_view_type = 'outfit view';
            } else {
                utag.data.list_view_type = 'product view';
            }
            utag.link(utag.data);
        });
    },


    searchOpen: function() {
      $body.on('search:open', function() {
        if (utagExists()) {
        utag.data.page_name = utag.data.store_country + ':search:open';
        // utag.data.page_type = 'search';
        // utag.data.event_name = 'search_open';
        // utag.link(utag.data);
        let page_name = utag.data.store_country + ':search:open';
        
        utag.view({
          'page_name' : page_name,
          'view_name': utag.data.store_country + ':search:open',
          'page_type' : 'search',
          "tealium_event" : 'search',
          'event_name' : 'search_open',
          'event_category' : 'searchOpen',
          'event_action' : 'click'
        });
      }
      });
    },


    searchTypeKeyword: function() {
      $body.on('search:searchKeyword', function(e, response) {
        if (utagExists()) {
          let names = [];
          let skus = [];
          for (let i = 0; i < response.suggestions.length; i++) {
            if (response.suggestions[i].dataset.productName) {
              names.push(response.suggestions[i].dataset.productName);
            }
            if (response.suggestions[i].dataset.pid) {
              skus.push(response.suggestions[i].dataset.pid);
            }
          }
          if (typeof utag === 'undefined' || !utag) return;
          utag.data.page_name = `${utag.data.store_country}:search:inline result`;
          // utag.data.page_type = 'search';
          // utag.data.search_results = response.suggestions.length;
          // utag.data.search_term = $('.search-field').val();
          // utag.data.product_impression_name = names.toString();
          // utag.data.product_impression_sku = skus.toString();
          // utag.data.event_name = 'search_inline';
          // utag.view(utag.data);


          utag.view({
            'page_name' : utag.data.store_country + ':search:inline result',
            'view_name': utag.data.store_country + ':search:inline result',
            'page_type' : 'search',
            "tealium_event" : 'search',
            'search_results' : response.suggestions.length,
            'search_term' : $('.search-field').val(),
            'product_name' : names,
            'product_sku' : skus,
            'event_name' : 'search_inline',
            'event_category' : 'searchInline',
            'event_action' : 'typeKeywords'
          });
        }
      });
    },

    searchProductClick: function() {
      $body.on('click', '.search-suggestion-list-item', function(e) {
        if (utagExists()) {
          utag.data.page_name = `${utag.data.store_country}:search:inline result`;
          // utag.data.page_type = 'search';
          // utag.data.search_term = $('.search-field').val();
          // utag.data.search_result_type = e.currentTarget.dataset.type;
          // utag.data.search_result_value = e.currentTarget.ariaLabel;
          // utag.data.product_impression_name = e.currentTarget.ariaLabel;
          // utag.data.product_impression_sku = e.currentTarget.dataset.pid;
          // utag.data.event_name = 'search_inline_click';
          // utag.view(utag.data);
          let page_name = utag.data.store_country + ':search:inline result';
          let name = [e.currentTarget.ariaLabel];
          let sku = [e.currentTarget.dataset.pid];


          utag.view({
            'page_name' : page_name,
            'view_name': utag.data.store_country + ':search:inline result',
            "tealium_event" : 'search',
            'page_type' : 'search',
            'search_term' : $('.search-field').val(),
            'search_result_type' : e.currentTarget.dataset.type,
            'search_result_value' : e.currentTarget.ariaLabel,
            'product_name' : name,
            'product_sku' : sku,
            'event_name' : 'search_inline_click',
            'event_category' : 'searchInlineClick',
            'event_action' : 'searchClick'
          });
        }
      });
    },

    searchSend: function() {
        $body.on('search:send', function() {
            if (utagExists()) {
                utag.view({
                    'page_name' : utag.data.store_country + ':search send',
                    'page_section' : 'search',
                    'page_type' : 'search',
                    'search_term' : $('.search-field').val(),
                    'event_name' : 'search_ok',
                });
                utag.link(utag.data);
            }
        });
    },

    searchResultsPage: function() {
      if (utagExists()) {
      $body.on('search:searchResultsPage', function() {
        let names = [];
        let skus = [];
        let product = $('.product');
        for (let i = 0; i < product.length; i++) {
          if (product[i].dataset.productName) {
            names.push(product[i].dataset.productName);
          }
          if (product[i].dataset.pid) {
            skus.push(product[i].dataset.pid);
          }
        }
        utag.data.page_name = utag.data.store_country + ':search';
        // utag.data.page_type = 'search';
        // utag.data.list_view_type = 'product view';
        // utag.data.product_impression_name = names.toString();
        // utag.data.product_impression_sku = skus.toString();


        utag.view({
          'page_name' : utag.data.store_country + ':search',
          'view_name': utag.data.store_country + ':search', 
          "tealium_event" : 'search',
          'page_type' : 'search',
          'list_view_type' : 'product view',
          'search_term' : $('.search-field').val(),
          'product_name' : names,
          'product_sku' : skus,
          'event_name' : 'search_results_page',
          'event_category' : 'searchResults',
          'event_action' : 'resultsLoad'
        });
      });
      }
    }
};
