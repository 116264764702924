module.exports = {
  updateDisabledOptions: function () {
    const isMobile = window.innerWidth <= 991;
    const selects = [...document.querySelectorAll('select')].filter(sel => {
      // sel.nextSibling && sel.nextSibling.matches('.fsb-select')
      return sel.nextSibling && sel.nextSibling.classList && sel.nextSibling.classList.contains('fsb-select');
    });

    const setDisabledElement = (selectElement) => {
      const options = selectElement.querySelectorAll(':scope > option');
      const customOptions = selectElement.nextSibling.querySelectorAll(':scope > .fsb-list > .fsb-option');
      const specialChars = /[|]/;
      for(let index = 0; index < options.length; index++) {
        options[index].value && customOptions[index] ? customOptions[index].setAttribute('value', options[index].value) : null;
        if((options[index].classList.contains('disabled') || options[index].getAttribute('disabled') !== null) && specialChars.test(options[index].innerHTML)) {
          let tmpCustomOption = customOptions[index].innerHTML.split('|')[0].replaceAll(' ', '');
          customOptions[index].innerHTML = tmpCustomOption;
          customOptions[index].classList.add('disabled-element');
        } else if(options[index].classList.contains('disabled') || options[index].getAttribute('disabled') !== null) {
          if(options[index].innerHTML.replaceAll(' ', '').replaceAll('\n', '') === customOptions[index].textContent) {
            customOptions[index].classList.add('disabled-element');
          } else {
            customOptions[index].classList.remove('disabled-element');
          }
        } else {
          customOptions[index] ? customOptions[index].classList.remove('disabled-element') : null;
        }
      }
    }

    if(selects){
      selects.forEach(sel => {
        if(isMobile) {
          sel.classList.add('fsb-ignore', 'mobile-version');
          sel.style.display = 'flex';
          sel.nextSibling.classList.add('hidden-field');
          setDisabledElement(sel);
        } else {
          sel.classList.remove('fsb-ignore', 'mobile-version');
          sel.style.display = 'none';
          sel.nextSibling.classList.remove('hidden-field');
          setDisabledElement(sel);
        }
      });
    }
  }
};
