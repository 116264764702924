'use strict';

let formCleared = false;

/**
 * Additional class to invalid-feed back when input field is changed
 * @param {HTMLElement} input input field
 */
function triggerEdgeValidation(input) {
  if (!input || !input.nextElementSibling) return;
  if (!input.nextElementSibling.classList.contains('invalid-feedback')) return;

  const invalidFeedback = input.nextElementSibling;
  invalidFeedback.classList.toggle('edge-validation-fix');
}

/**
 * Scroll to first error in given form (if there is any)
 * @param {JQuery} $form - jQuery object containing form
 */
function scrollToError($form) {
  if ($form.find('.is-invalid,.-invalid').length && !$form.parents('.modal').length) {
    $('html, body').animate(
      {
        scrollTop: $form.find('.is-invalid,.-invalid').first().offset().top - 25,
      },
      500
    );
    $form.find('.is-invalid:first,.-invalid:first').focus();
  }
}

/**
 * Check if the user has done the reCaptcha and return true if so
 * @param {jQuery} $form - the from which contains the reCaptcha
 * @returns {boolean} whether  the reCaptcha is entered or not
 */
function validateReCaptcha($form) {
  const $response = $form.find('[name=g-recaptcha-response]');
  let valid;

  if ($response.length && $response.val()) {
    // ReCaptcha found and valid
    valid = true;
  } else if ($response.length) {
    // ReCaptcha found but not valid
    valid = false;
  } else {
    // ReCaptcha not found
    valid = true;
  }

  $response.parent().toggleClass('highlight-recaptcha', !valid);
  return valid;
}

/**
 * Validate whole form. Requires `this` to be set to form object
 * @param {jQuery.event} event - Event to be canceled if form is invalid.
 * @returns {boolean} - Flag to indicate if form is valid
 */
function validateForm(event) {
  let valid = true;

  if (!validateReCaptcha($(this)) || (this.checkValidity && !this.checkValidity())) {
    // safari
    valid = false;

    if (event) {
      event.preventDefault();
      event.stopPropagation();
      event.stopImmediatePropagation();
    }

    $(this)
      .find('input, select, textarea')
      .each(function () {
        if (!this.validity.valid) {
          $(this).trigger('invalid', this.validity);
        }
      });
  }

  return valid;
}

/**
 * Remove all validation. Should be called every time before revalidating form
 * @param {element|jQuery} form - Form to be cleared. Could be plain JS object or jQuery object
 * @returns {void}
 */
function clearFormErrors(form) {
  let $form = form;

  // Transform to jQuery object if plain JS object is passed.
  if (!form.jquery) {
    $form = $(form);
  }

  $form.find('.form-control.is-invalid').removeClass('is-invalid');
  $form.find('.form-control.is-valid').removeClass('is-valid');
  // margiela
  $form.find('.-invalid').removeClass('-invalid');
  $form.find('-valid').removeClass('-valid');
  
  $form.find('.invalid-feedback').empty();
}

/**
 * Init the handlers for events which doesn't bubble.
 * No bubbling means that body, document or element's container cannot be used for that event
 * and the exact element should be selected instead.
 * This is not a problem on initial load but doesn't work on html loaded with Ajax
 * and this is why this method is needed so we can re-attach the event handlers.
 * @returns {void}
 */
function attachNoBubbleEvents() {
  let attachTimeout;
  $('form input, form select, form textarea')
    .off('invalid')
    .on('invalid', function (e) {
      e.preventDefault();
      this.setCustomValidity('');
      const $form = $(this).parents('form');
      triggerEdgeValidation(this);

      if (!this.validity.valid) {
        $(this).removeClass('is-valid -valid');
        $(this).addClass('is-invalid -invalid');

        const id = $(this).attr('id');
        const fieldMessages = window.formValidationMessages[id];

        if (fieldMessages) {
          // Important:
          // for-in statement was the only possible solution in this case.
          // ValidityState has no enumerable properties hence Object.keys() wouldn't work.
          let key;

          for (key in this.validity) {
            // eslint-disable-line no-restricted-syntax, guard-for-in
            if (key !== 'valid') {
              if (this.validity[key] && fieldMessages[key]) {
                this.setCustomValidity(fieldMessages[key]);
                break;
              }
            }
          }
        }

        $(this).parents('.form-group').find('.invalid-feedback').text(this.validationMessage);
        $(this).parents('[class^="mm-form"]').find('.invalid-feedback').text(this.validationMessage);

        clearTimeout(attachTimeout);
        attachTimeout = setTimeout(function () {
          $body.trigger('validation:afterReattachInvalid', { $form });
        }, 50);
      }
    });
}

/**
 * Clear errors from form fields (includes messeges and visual feedback)
 * @param {JQuery} $form - jquery form object
 */
function clearFormFieldErrors($form) {
  $form.siblings('.invalid-feedback').empty();
  $form.removeClass('is-invalid -inalid');
  $form.addClass('is-valid -valid');

  $form.find('input').each((i, e) => {
    triggerEdgeValidation(e);
  });
}

module.exports = {
  noBubbleEvents: attachNoBubbleEvents,

  init: function (additionalMessagesStr) {
    window.formValidationMessages = {};

    let additionalMessages;

    if (additionalMessagesStr) {
      try {
        additionalMessages = JSON.parse(additionalMessagesStr);
      } catch (e) {
        window.console.warn(e);
      }
    }

    const $formMessages = $('.js-form-messages');

    if ($formMessages.length) {
      try {
        window.formValidationMessages = JSON.parse($formMessages.text());
      } catch (e) {
        window.console.warn(e);
      }
    }

    if (additionalMessages) {
      $.extend(window.formValidationMessages, additionalMessages);
    }

  },

  submit: function () {
    $body.on('submit', 'form', function (e) {
      return validateForm.call(this, e);
    });
  },

  buttonClick: function () {
    $body.on('click', 'form button[type="submit"], form input[type="submit"]', function () {
      // clear all errors when trying to submit the form
      const $form = $(this).parents('form');
      clearFormErrors($form);
      validateReCaptcha($form);
      formCleared = true;
    });
  },

  focusOut: function () {
    $body.on('focusout', 'form input:not(.validate-on-change), form select:not(.validate-on-change), form textarea', function (e) {
      const $this = $(e.target);
      $this.siblings('.field-description').remove();

      if (!this.checkValidity()) {
        $this.trigger('invalid', this.validity);
      } else {
        clearFormFieldErrors($this);
      }
    });
  },

  blur: function () {
    $body.on('blur', 'form input:not(.validate-on-change), form select:not(.validate-on-change), form textarea', function (e) {
      const $this = $(e.target);

      const removeWhitespaces = $($this).val();
      const cleanString = removeWhitespaces.replace(/^\s+|\s+$/g, '');
      $($this).val(cleanString);
      $this.siblings('.field-description').remove();

      if (!this.checkValidity()) {
        $this.trigger('invalid', this.validity);
      } else {
        clearFormFieldErrors($this);
      }
    });
  },

  onChange: function () {
    $body.on('change', 'input.validate-on-change, select.validate-on-change', function (e) {
      const $this = $(e.target);

      if (!this.checkValidity()) {
        $this.trigger('invalid', this.validity);
      } else {
        clearFormFieldErrors($this);
      }
    });
  },

  handleDateChange: function () {
    $body.on('changeDate', '[data-provide=datepicker]', function () {
      $(this).trigger('change');
      $(this).trigger('focusout');
    });
  },

  scrollOnError: function () {
    $body.on('validation:afterReattachInvalid', function (e, data) {
      if (formCleared) {
        formCleared = false;
        scrollToError(data.$form);
      }
    });
  },

  updatePostalCodePattern: function () {
    $body.on('change', '[data-update-postal-code-pattern]', function () {
      const $this = $(this);
      const postalCodeElementID = $this.data('update-postal-code-pattern');
      const $postalCode = $this.closest('form').find('#' + postalCodeElementID);

      if ($postalCode.length > 0) {
        const postalCodeRegexp = {
          defaults: /^[0-9]{5}$/,
          at: /^(?!0)([0-9]{4})$/,
          be: /^(?!0)([0-9]{4})$/,
          bg: /^(?!0)([0-9]{4})$/,
          dk: /^(?!0)([0-9]{4})$/,
          gr: /^([0-9]{3}\s[0-9]{2}|[0-9]{5})$/,
          hu: /^[0-9]{4}$/,
          lv: /^([Ll][Vv])[-]([0-9]){4}$/,
          lt: /^([Ll][Tt])[-]([0-9]){5}$/,
          lu: /^(?!0)([0-9]{4})$/,
          nl: /^[1-9][0-9]{3}\s[A-Z]{2}$/,
          no: /^[0-9]{4}$/,
          pl: /^[0-9]{2}[-][0-9]{3}$/,
          pt: /^(?!0)([0-9]{4}-?[0-9]{3})$/,
          ro: /^[0-9]{6}$/,
          ru: /^[0-9]{6}$/,
          sk: /^(?=0|8|9)([0-9]{3}\s?[0-9]{2})$/,
          si: /^(?!0)([0-9]{4}$)/,
          es: /^((0[1-9]|5[0-2])|[1-4][0-9])[0-9]{3}$/,
          se: /^([0-9]{3}\s[0-9]{2}|[0-9]{5})$/,
          ch: /^[0-9]{4}$/,
          gb: /^([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})$/,
          us: /^\d{5}(-\d{4})?$/,
          ca: /^[ABCEGHJKLMNPRSTVXYabceghjklmnprstvxy]{1}\d{1}[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz]{1} *\d{1}[ABCEGHJKLMNPRSTVWXYZabceghjklmnprstvwxyz]{1}\d{1}$/,
          cz: /^[0-9]{3}\s[0-9]{2}$/,
        };
        const countryCode = ($this.val() || '').toLowerCase();
        const regexp = countryCode in postalCodeRegexp ? postalCodeRegexp[countryCode] : postalCodeRegexp.defaults;

        $postalCode.attr('pattern', regexp.toString().slice(1, -1));
      }
    });

    $('[data-update-postal-code-pattern]').trigger('change');
  },

  reCaptchaOnChange: function () {
    $body.on('focusin', '.g-recaptcha iframe', function () {
      validateReCaptcha($(event.target).parents('form'));
    });
  },

  functions: {
    validateForm: function (form, event) {
      return validateForm.call(form, event || null);
    },
    clearFormErrors: clearFormErrors,
    reAttachNoBubbleEvents: attachNoBubbleEvents,
    scrollToError: scrollToError,
  },
};
