'use strict';

let processInclude = require('core/util');

$(document).ready(function () {
  const isMargiela = document.body.dataset.sitebrand === "Margiela";

  // MARG-841 MARG-774
  const selector = isMargiela
    ? '#login-page, #guest-login-page, #account-passwordreset, #account-setnewpassword, #account-passwordupdatejourneyfrompm, #account-passwordupdatejourneyfromcampaign, #login-show'
    : '#login-page, #guest-login-page';
  if ($(selector).length > 0) {
    processInclude(require('core/login/login'));
  }

  // MARG-772
  if (isMargiela) {
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.has('password-reset-token')) {
      $.spinner().start();
      const result = $('<div></div>');
      const selector = '[data-option-modalopenid="new-password"],[data-option-modalopenid="forgot-password"]';
      result.load($('.mm-menu-logo > a').attr('href') + 'account-setnewpassword?'
        + $.param({ token: searchParams.get('password-reset-token'), journey: searchParams.get('journey') })
        + ' ' + selector, (response, status, xhr) => {
        $.spinner().stop();

        // console.log('status', status, 'xhr', xhr);
        // console.log('xhr.statusCode', xhr.statusCode, "xhr.getResponseHeader('Location')", xhr.getResponseHeader('Location'));
        
        if (status === 'error') return; // TODO

        if (xhr.statusCode === 302) {
          $.spinner().start();
          result.load(xhr.getResponseHeader('Location') + '?'
            + $.param({ journey: searchParams.get('journey') })
            + ' ' + selector, (response2, status2, xhr2) => {
            $.spinner().stop();

            // console.log('status2', status2, 'xhr2', xhr2);

            if (status === 'error') return; // TODO

            result.find(selector).attr({ 'data-option-immediate': 'true', 'data-option-delay': '10' });
            $(document.body).append(result); // .contents());
            processInclude(require('core/login/login'));
          });
          return;
        }

        result.find(selector).attr({ 'data-option-immediate': 'true', 'data-option-delay': '10' });
        $(document.body).append(result); // .contents());
        processInclude(require('core/login/login'));
      });
    }
  }
});

$(document).on('login-dropdown:openRemote login-modal:openRemote', function (e) {
  processInclude(require('core/login/login'));
});
