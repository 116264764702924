'use strict';

let formValidation = require('../components/formValidation');
let createErrorNotification = require('../components/errorNotification');
/* This var is used as workaround to prevent multiple submit in login page */
let divId = 'loginSubmitInProgress';

const isMargiela = document.body.dataset.sitebrand === "Margiela";
const isJilSander = document.body.dataset.sitebrand === "JilSander";

module.exports = {
  login: function () {
    // console.log('login');
    $('form.login').on('bouncerFormValid', function (e) {
      // console.log('login bouncerFormValid');
      e.preventDefault();

      let myEle = document.getElementById(divId);
      if (myEle) {
        return false;
      }

      /* workaround to prevent multiple submit in login page */
      let div = document.createElement('div');
      div.id = divId;
      document.body.appendChild(div);

      let form = $(this);
      e.preventDefault();
      let url = form.attr('action');
      $.spinner().start();
      $('form.login').trigger('login:submit', e);
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
          // console.log('login bouncerFormValid success', data);
          $.spinner().stop();
          document.getElementById(divId).remove();
          if (!data.success && data.journeyMsg) {
            if(document.querySelectorAll('.journey-start.js_journey-start').length < 1) {

             if(isJilSander){
              $(data.journeyMsg).insertAfter('.js-login-box');
             } else{
              $(data.journeyMsg).insertAfter('.login-content .alert-danger');
             }
            }
            $('form.login').trigger('login:error', data);
          } else if (!data.success) {
            formValidation(form, data);
            $.spinner().stop();
          } else {
            $('form.login').trigger('login:success', data);

            //Tealium event "When the user correctly login"
            $('body').trigger('user:loggedIn');
            location.href = data.redirectUrl;
          }
        },
        error: function (data) {
          document.getElementById(divId).remove();
          if (data.responseJSON.redirectUrl) {
            window.location.href = data.responseJSON.redirectUrl;
          } else {
            $('form.login').trigger('login:error', data);
            $.spinner().stop();
          }
        },
      });
      return false;
    });
  },

  guestlogin: function () {
    $('form.guest-login').on('bouncerFormValid', function (e) {
      e.preventDefault();

      let myEle = document.getElementById(divId);
      if (myEle) {
        return false;
      }

      /* workaround to prevent multiple submit in login page */
      let div = document.createElement('div');
      div.id = divId;
      document.body.appendChild(div);

      let form = $(this);
      e.preventDefault();
      let url = form.attr('action');
      $.spinner().start();
      $('form.guest-login').trigger('login:submit', e);
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
          $.spinner().stop();
          document.getElementById(divId).remove();
          if (!data.status) {
            formValidation(form, data);
            //$('form.guest-login').trigger('login:error', data);
          } else {
            //$('form.guest-login').trigger('login:success', data);
            location.href = data.redirectUrl;
          }
        },
        error: function (data) {
          document.getElementById(divId).remove();
          if (data.responseJSON.redirectUrl) {
            window.location.href = data.responseJSON.redirectUrl;
          } else {
            $('form.guest-login').trigger('login:error', data);
            $.spinner().stop();
          }
        },
      });
      return false;
    });
  },

  register: function () {
     // console.log('register');
    $('form.registration').on('bouncerFormValid', function (e) {
      // console.log('register bouncerFormValid');
      let form = $(this);
      e.preventDefault();
      let url = form.attr('action');
      $.spinner().start();
      $('form.registration').trigger('login:register', e);
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
          $.spinner().stop();
          if (!data.success) {
            $('form.registration').trigger('login:register:error', data);
            formValidation(form, data);
          } else {
            $('form.registration').trigger('login:register:success', data);
            location.href = data.redirectUrl;
          }
        },
        error: function (err) {
          if (err.responseJSON.redirectUrl) {
            window.location.href = err.responseJSON.redirectUrl;
          } else {
            createErrorNotification($('.error-messaging'), err.responseJSON.errorMessage);
          }

          $.spinner().stop();
        },
      });
      return false;
    });
  },

  resetPassword: function () {
    // console.log('resetPassword');
    $('.reset-password-form').on('bouncerFormValid', function (e) {
      console.log('reset-password-form bouncerFormValid');
      let form = $(this);
      e.preventDefault();
      $.spinner().start();
      $('.reset-password-form').trigger('login:register', e);

      var resetPasswordAjax = function (form) {
        let url = form.attr('action');
        $.ajax({
          url: url,
          type: 'post',
          dataType: 'json',
          data: form.serialize(),
          success: function (data) {
            $.spinner().stop();
            if (!data.success) {
              formValidation(form, data);
            } else {
              if (isMargiela) {
                $('#forgot-password #modal-forgot-password').addClass('d-none');
                $('#forgot-password #modal-forgot-password-confirm').removeClass('d-none');
                // MARG-791
                if (form.closest('.modal.is-open').length === 0) {
                  $('#forgot-password').closest('[data-component="ModalTriggerComponent"]').trigger('click');
                }
              } else if(isJilSander){
                $('.js-reset-password-wrapper').addClass('d-none');
                $('.js-recover-password-confirm').removeClass('d-none');
              } else{
                document.getElementById('modal-forgot-password').style.display = 'none';
                document.getElementById('modal-forgot-password-confirm').style.display = 'block';
              }

              // $('.request-password-title').text(data.receivedMsgHeading);
              // $('.request-password-body')
              //   .empty()
              //   .append('<p>' + data.receivedMsgBody + '</p>');

              //   if (!data.mobile) {
              //   $('#submitEmailButton').text(data.buttonText).attr('data-dismiss', 'modal');
              // }
              // else {
              //   $('.send-email-btn')
              //     .empty()
              //     .html('<a href="' + data.returnUrl + '" class="btn btn-primary btn-block">' + data.buttonText + '</a>');
              // }
            }
          },
          error: function () {
            $.spinner().stop();
          },
        });
      };

      if ($('.g-recaptcha').length > 0) {
        grecaptcha.ready(function() {
          grecaptcha.execute($('[id^="gReCaptchaResetPassword"]').data('sitekey'), {action:'submit'}).then(function(token) {
            $('[id^="gReCaptchaResetPassword"]').val(token);
            resetPasswordAjax(form);
          });
        });
      } else {
        resetPasswordAjax(form);
      }
      return false;
    });
  },

  clearResetForm: function () {
    $('#login .modal').on('hidden.bs.modal', function () {
      $('#reset-password-email').val('');
      $('.modal-dialog .form-control.is-invalid').removeClass('is-invalid');
    });
  },

  changePassword: function () {
    // console.log('changePassword');
    $('.change-password-form').on('bouncerFormValid', function (e) {
      // console.log('change-password-form bouncerFormValid');
      let form = $(this);
      e.preventDefault();
      let url = form.attr('action');
      $.spinner().start();
      // $('.reset-password-form').trigger('login:register', e);
      $.ajax({
        url: url,
        type: 'post',
        dataType: 'json',
        data: form.serialize(),
        success: function (data) {
          // console.log('change-password-form bouncerFormValid success', data);
          $.spinner().stop();
          if (!data.success) {
            formValidation(form, data);
          } else {
            if (isMargiela) {
              document.getElementById('modal-new-password').classList.add('d-none');
              document.getElementById('modal-new-password-confirm').classList.remove('d-none');
            } else if(isJilSander){
              window.location.href = data.redirectUrl;
              // document.querySelector('.js-reset-password-form').classList.add('d-none');
              // document.querySelector('.js-reset-password-confirm').classList.remove('d-none');
            } else {
              document.getElementById('modal-new-password').style.display = 'none';
              document.getElementById('modal-new-password-confirm').style.display = 'block';
            }
          }
        },
        error: function () {
          $.spinner().stop();
        },
      });
      return false;
    });
  },
};
