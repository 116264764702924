const returnChangeType = document.querySelectorAll('.select-return-change-type .radio');
const returnReason = document.getElementById('return-reason-option');
const returnQuantity = document.getElementById('return-quantity');
const keepArticleQuestion = document.getElementById('keep-article-question');
const sizeChangeOptions = document.getElementById('size-change-option');
const colorChangeOptions = document.getElementById('color-change-option');

if (returnChangeType) {
  returnChangeType.forEach((el) => {
    el.addEventListener('click', (data) => {
      if (returnQuantity) {
        returnQuantity.showReturnQuantity();
      }

      showKeepArticleQuestion();
      if (data.target.value) {
        switch (data.target.value) {
          case 'return':
            showReturn();
            break;
          case 'size-change':
            showSizeChange();
            break;
          case 'color-change':
            showColorChange();
            break;

          default:
            break;
        }
      }
    });
  });
}

const showReturnQuantity = () => returnQuantity.classList.add('show');
const showKeepArticleQuestion = () => (keepArticleQuestion ? keepArticleQuestion.classList.add('show') : null);
const showReturn = () => {
  hideElements(sizeChangeOptions, colorChangeOptions);
  if (returnReason) {
    returnReason.classList.add('show');
  }
};

const showSizeChange = () => {
  hideElements(returnReason, colorChangeOptions);
  sizeChangeOptions.classList.add('show');
};

const showColorChange = () => {
  hideElements(returnReason, sizeChangeOptions);
  colorChangeOptions.classList.add('show');
};

const hideElements = (el1, el2, el3) => {
  el1 ? el1.classList.remove('show') : null;
  el2 ? el2.classList.remove('show') : null;
  el3 ? el3.classList.remove('show') : null;
};

// const showElements() = () => {}
